<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3 v-if="!esNuevo">{{ titleEditar }}</h3>
        <h3 v-else>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <card-form :style="combinedStyles" title="Informacion básica">
          <template>
            <card-form-item title="Código">
              <template #description>
                <input-box v-model="itemMod.codigo" v-bind="form.codigo" />
              </template>
            </card-form-item>
            <card-form-item title="Nombre">
              <template #description>
                <input-box v-model="itemMod.nombre" v-bind="form.nombre" />
              </template>
            </card-form-item>
            <card-form-item title="Proveedor">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_proveedor"
                    :filter="true"
                    :options="opcionesProveedor"
                    v-bind="form.nombre_proveedor"
                  />
                </div>
              </template>
            </card-form-item>
            <card-form-item v-if="!loading" title="Laboratorio">
              <template #description>
                <div>
                  <select-box
                    :multiple="true"
                    :clear-icon="true"
                    :v-model="itemMod.laboratoriosseleccionados"
                    :options="opcionesLaboratorio"
                    :filter="true"
                  />
                </div>
              </template>
            </card-form-item>
            <card-form-item title="Usuario fedicom">
              <template #description>
                <input-box
                  v-model="itemMod.user_fedicom"
                  v-bind="form.user_fedicom"
                />
              </template>
            </card-form-item>
            <card-form-item title="Contraseña fedicom">
              <template #description>
                <input-box
                  v-model="itemMod.pass_fedicom"
                  v-bind="form.pass_fedicom"
                />
              </template>
            </card-form-item>
            <card-form-item title="Volumen pactado">
              <template #description>
                <input-box
                  v-model="itemMod.volumen_pactado"
                  v-bind="form.volumen_pactado"
                />
              </template>
            </card-form-item>
            <card-form-item title="Descuento inicial">
              <template #description>
                <input-box
                  v-model="itemMod.dto_inicial"
                  v-bind="form.dto_inicial"
                />
              </template>
            </card-form-item>
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import farmacias from '@/api/farmacias.api';
import proveedores from '@/api/proveedores.api';
import laboratorios from '@/api/laboratorios.api';
import colors from '@/mixins/colors.js';
import CheckSelector from '../../commons/forms/CheckSelector.vue';
import MultipleSelectCustom from '../../commons/forms/MultipleSelectCustom.vue';

export default {
  name: 'ModalFarmacias',
  components: { MultipleSelectCustom, CheckSelector },
  extends: farmacias,
  mixins: [colors, proveedores, laboratorios],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    esNuevo: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      titleEditar: 'Editar',
      titleCrear: 'Crear',
      itemMod: {},
      opcionesProveedor: [],
      opcionesLaboratorio: [],
      form: {
        codigo: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre_proveedor: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        laboratorios: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        user_fedicom: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        pass_fedicom: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        volumen_pactado: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        dto_inicial: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      esEstandar: true,
    };
  },
  mounted() {
    this.loadFiltros();
    this.loadLaboratorios();
  },
  methods: {
    loadDatosFarmacia() {
      if (this.id) {
        let createPromise = new Promise((resolve) => {
          this.findFarmacia(resolve, this.id);
        });
        createPromise.then((data) => {
          if (data) {
            console.log(data);
            this.itemMod = data;
            this.itemMod.laboratoriosseleccionados = [];
            data.laboratorios.forEach((element) =>
              this.itemMod.laboratoriosseleccionados.push(
                element.id_laboratorio
              )
            );

            console.log(
              'laboratorios seleccionados ',
              this.itemMod.laboratoriosseleccionados
            );
          }
        });
      }
    },
    actualizarCheck: function (data) {
      console.log(data);
    },
    handleSelect: function (data) {
      console.log(data);
    },

    guardar() {
      this.cleanForm();
      if (!this.validateForm()) {
        return;
      }
      this.itemMod = {
        ...this.itemMod,
      };
      if (this.esNuevo) {
        let createPromise = new Promise((resolve) => {
          this.createFarmacia(resolve, this.itemMod);
        });
        createPromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      } else {
        let updatePromise = new Promise((resolve) => {
          this.updateFarmacia(resolve, this.itemMod, this.itemMod.id_farmacia);
        });
        updatePromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      }
    },
    loadFiltros: function () {
      let proveedoresPromise = new Promise((resolve) => {
        this.getProveedores(resolve);
      });
      Promise.all([proveedoresPromise]).then(([proveedoresData]) => {
        this.opcionesProveedor = proveedoresData.map((item) => {
          item.text = item.nombre;
          item.value = item.id_proveedor;
          return item;
        });
      });
    },
    loadLaboratorios: function () {
      this.loading = true;
      let laboratoriosPromise = new Promise((resolve) => {
        this.getLaboratorios(resolve);
      });
      laboratoriosPromise.then((data) => {
        this.opcionesLaboratorio = [];
        data.forEach((element) => {
          this.opcionesLaboratorio.push({
            value: element.id_laboratorio,
            text: element.nombre,
          });
        });
        this.loading = false;
        this.loadDatosFarmacia();
      });
    },

    validateForm() {
      let validado = true;
      if (!this.itemMod.descripcion === '') {
        this.form.descripcion.hasError = true;
        this.form.descripcion.hasValidation = true;
        this.form.descripcion.errors.push({
          show: true,
          message: this.$t('error.obligatorio.default'),
        });
        validado = false;
      }
      return validado;
    },
    cleanForm: function () {
      this.form = {
        descripcion: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scope>
.form {
  overflow: visible;
}
</style>
