<template>
  <modal size="sm" padding-size="sm" @hide="$emit('close')">
    <template>
      <div id="modal-change-email">
        <h3>Correo electrónico</h3>
        <p>Introduce tu correo electrónico de contacto</p>

        <div class="mt-5 mb-5">
          <input-box v-model="email" label="Email" type="email" class="mb-4" />

          <check-box
            label="Autorizo a recibir correos electrónicos automáticos con los informes adjuntos en el correo indicado"
          />
        </div>

        <div class="d-flex">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('generals.cancel')
          }}</b-button>
          <b-button variant="primary" class="ml-3" @click="$emit('close')">{{
            $t('generals.apply')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ModalChangeEmail',
  data() {
    return {
      email: '',
    };
  },
  methods: {},
};
</script>

<style lang="scss">
#modal-change-email {
  .form-error-box.min-height {
    min-height: 0;
  }
}
</style>
