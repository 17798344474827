<template>
  <div class="multiple-select-custom-subtoptions">
    <div
      v-for="(suboption, index) in suboptions"
      :key="suboption.value"
      class="multiple-select-custom-subtoption"
      :class="{ active: suboption.selected }"
      @click="selected(index)"
    >
      {{ suboption.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleSelectInnerNormal',
  props: ['suboptions'],
  data() {
    return {
      options: [],
    };
  },
  created() {
    this.options = this.suboptions;
  },
  methods: {
    selected: function (index) {
      this.options.forEach((item) => {
        item.selected = false;
      });
      this.options[index].selected = !this.options[index].selected;

      this.$emit('selected', this.options[index]);
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-select-custom-subtoption {
  white-space: nowrap;
  color: var(--fc-brand-02) !important;
  padding: 9px 12px;
  border-radius: 6px;
  &:hover {
    background: var(--fc-brand-01-soft);
  }
  &.active {
    background-color: var(--fc-brand-01);
    color: #fff !important;
    &:hover {
      background-color: var(--fc-brand-01);
    }
  }
}
</style>
