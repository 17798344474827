<template>
  <modal-confirmation
    :title="title"
    :description="descripcion"
    :hide-buttons="true"
    :show-x="true"
    :style="colorStyles"
    @hide="$emit('close')"
  >
    <template #header>
      <h3>{{ title }}</h3>
    </template>
    <template>
      <div class="p-4">
        <h5>{{ descripcion }}</h5>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center" :style="colorStyles">
        <b-button
          class="cta"
          variant="outline-primary"
          @click="$emit('close')"
          >{{ $t('button.cancel') }}</b-button
        >
        <b-button class="ml-3 cta" @click="$emit('confirm')">{{
          $t('button.confirmar')
        }}</b-button>
      </div>
    </template>
    <template #bottom>
      <div class="d-flex justify-content-center" :style="colorStyles">
        <b-button
          class="cta"
          variant="outline-primary"
          @click="$emit('close')"
          >{{ $t('button.cancel') }}</b-button
        >
        <b-button class="ml-3 cta" @click="$emit('confirm')">{{
          $t('button.confirmar')
        }}</b-button>
      </div>
    </template>
  </modal-confirmation>
</template>
<script>
import colors from '@/mixins/colors.js';
export default {
  name: 'ModalConfirmacion',
  // extends:ModalConfirmation,
  mixins: [colors],
  props: {
    showConfirmation: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Confirmación',
    },
    descripcion: {
      type: String,
      default: '¿Desea confirmar la operación?',
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import 'frontendcommon/src/scss/config';
.custom-calendar {
  border-width: 0 !important;
}
::v-deep .vc-header {
  margin-bottom: 30px;
}
.header-button {
  font-size: 20px;
  color: $dark-grey;
}
.header-title {
  @include button();
  color: $dark-grey;
}
::v-deep .vc-weekday {
  @include h8();
  color: $medium-grey;
}
::v-deep .vc-day {
  position: relative;
  border: 1px solid $light-grey;
  padding-top: 90%;
}
::v-deep .is-today .day-header span {
  background: $brand-03;
  color: white;
}

.hide-arrows ::v-deep .vc-arrow {
  display: none;
}
::v-deep .vc-grid-container {
  overflow: visible;
}
</style>
