<template>
  <div class="card-widget">
    <div class="white-card-header">
      <slot class="w100" name="header"> </slot>
    </div>
    <div class="white-card-body">
      <slot name="content"> </slot>
    </div>
    <div class="white-card-footer">
      <slot name="footer"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSimpleWidget',
};
</script>

<style lang="scss" scoped>
.card-widget {
  width: 100%;
  border: 1px solid #c5c7cd4d;
  // display: inline-block;
  margin-bottom: 2rem;
  background-color: #fff;
  .white-card-header {
    width: 100%;
    // height: 60px;
    padding: 1rem 1rem 1rem 1rem;
    border-bottom: 1px solid rgba(197, 199, 205, 0.3);
    .header-left {
      display: inline-flex;
      align-items: center;
    }
    .header-right {
      display: inline-flex;
      align-items: center;
    }
  }
  .white-card-body {
    border-bottom: 1px solid rgba(197, 199, 205, 0.3);
  }
  //    .white-card-footer{
  //     padding: 1rem 1rem 1rem 1rem;
  //    }
}
// .card-widget {
//     width: 100%;
//     border: 1px solid #C5C7CD4D;
//     background-color: #fff;
//     .white-card-header {
//         width: 100%;
//         height: 60px;
//         padding: 1rem 1rem 1rem 1rem;
//         border-bottom: 1px solid rgba(197, 199, 205, 0.3);
//         display: inline-block;
//         // align-items: center;
//         // justify-content: space-between;
//     }
// }
</style>
