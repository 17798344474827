<template>
  <FilterFarmacias />
</template>

<script>
import home from '@/api/home.api';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { CardSearchLinea, CardSearchCabecera } from '@/components';
import SelectCustom from '../../commons/forms/SelectCustom.vue';
import CheckSelectorCollapse from '../../commons/forms/CheckSelectorCollapse.vue';
import CheckSelectorCollapseLoad from '../../commons/forms/CheckSelectorCollapseLoad.vue';
import FilterFarmacias from '../../commons/FilterFarmacias.vue';

export default {
  name: 'Home',
  components: {
    CardSearchLinea,
    SelectCustom,
    CheckSelectorCollapse,
    CardSearchCabecera,
    CheckSelectorCollapseLoad,
    FilterFarmacias,
  },
  extends: home,
  data() {
    return {
      cooperativa: [],
      searchObject: {
        buscador: '',
        orderBy: 'FECHARECEPCION desc',
        pageSize: 25,
        pageNumber: 1,
      },
      searchFilters: {
        cooperativa: [],
        proveedor: [],
        cliente: [],
        provincia: [],
        division: [],
        tipo: [
          {
            value: 'DO1,DO2,DO3,DO4,DO5',
            text: 'DOL',
            selected: false,
            type: 'check',
          },
          {
            value: 'TR',
            text: 'TR',
            selected: false,
            type: 'check',
          },
        ],
        tipovista: [
          {
            value: 'cabecera',
            name: 'Cabecera',
            selected: true,
          },
          {
            value: 'linea',
            name: 'Linea',
          },
        ],
        situacion: [
          {
            value: 'PENDIENTE',
            text: 'Pendiente',
            selected: false,
            type: 'check',
          },
          {
            value: 'EN CURSO',
            text: 'En curso',
            selected: false,
            type: 'check',
          },
          {
            value: 'SERVIDO',
            text: 'Servido',
            selected: false,
            type: 'check',
          },
        ],
        grupal: [
          {
            value: 'si',
            text: 'Sí',
            selected: false,
            type: 'select',
          },
          {
            value: 'no',
            text: 'No',
            selected: false,
            type: 'select',
          },
        ],
        surtido: [
          {
            value: '0',
            text: 'No surtido',
            selected: false,
            type: 'select',
          },
          {
            value: '1',
            text: 'Surtido cantidad',
            selected: false,
            type: 'select',
          },
          {
            value: '2',
            text: 'Surtido importe',
            selected: false,
            type: 'select',
          },
        ],
        idTransferLab: '',
      },
      vista: 'cabecera',
      productSelected: null,
      totalResults: 0,
      pageNumber: 1,
      showFilters: true,
      showFiltersModal: false,
      lgModal: true,
      numberOptionsOriginals: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '25',
          value: 25,
          selected: true,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
      ],
      numberOptions: [],
      results: [],
      orderByOptions: [
        {
          value: 'FECHARECEPCION desc',
          name: this.$t('search.filters.orderByfechaRecepción'),
          selected: true,
        },
        {
          value: 'tipo',
          name: this.$t('search.filters.orderBytipo'),
        },
        {
          value: 'grupal',
          name: this.$t('search.filters.orderBygrupal'),
        },
        {
          value: 'ID_FARMACIA',
          name: this.$t('search.filters.orderBycliente'),
        },
      ],
      loading: false,
      filterOpened: null,
      totalFiltersLoad: 0,
      fechaRecepcion: {
        start: moment().clone().subtract(7, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
    };
  },
};
</script>

<style lang="scss">
#transfer-container {
  // margin-left: 10%;
  // overflow-y:scroll;
  // overflow-x:hidden;
  width: 100%;
  background-color: #f7f8fc;

  .fixed-header {
    // position: fixed;
    // z-index: 999;

    .transfer-header {
      background-color: #fff;
      padding: 1% 5% 5% 5%;
      width: 100% !important;
    }

    .transfer-filters {
      padding: 0 5% 1% 5%;
    }
  }

  .transfer-body {
    padding: 0 5% 5% 5%;

    .transfer-listado {
      padding-top: 20px;
    }
  }

  .search-select-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: 0px 1px 4px rgba(188, 186, 186, 0.25);
    background-color: #fff;
    margin-top: -30px;
    position: relative;

    @media screen and (max-width: 655px) {
      flex-direction: column-reverse;
    }

    .input-custom,
    .select-custom,
    .fecha-selector {
      box-shadow: none;
      border: none;
    }
    .fecha-selector {
      flex-basis: 25%;
      border-radius: 5px;
      @media screen and (max-width: 655px) {
        flex-basis: 100%;
        width: 100%;
        border-top: 1px solid rgba(188, 186, 186, 0.25);
      }
      .inputbox-input {
        border-bottom: none;
      }
      @media screen and (min-width: 655px) {
        &:after {
          content: '';
          position: absolute;
          top: 10px;
          right: 0;
          width: 1px;
          height: 30px;
          background-color: #e2e2e2;
        }
      }
    }
    .input-custom {
      position: relative;
      background-color: transparent;
      // min-width: 300px;
      flex-basis: 55%;
      @media screen and (max-width: 655px) {
        border-top: 1px solid rgba(188, 186, 186, 0.25);
      }

      @media screen and (min-width: 655px) {
        &:after {
          content: '';
          position: absolute;
          top: 10px;
          right: 0;
          width: 1px;
          height: 30px;
          background-color: #e2e2e2;
        }
      }
    }

    .select-custom {
      width: 245px;
      flex-basis: 20%;
    }
  }

  .search-filter-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    gap: 0.5rem;
  }
}
</style>
