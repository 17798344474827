import store from '../store';

const accessToken = store.getters.getAccessToken;
import httpClient from './httpClient';

const PATH = '/catalogo-sellout/';

export default {
  methods: {
    getTiposArticulos: function (resolve, request) {
      httpClient
        .get(PATH + 'indextipos', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategorias: function (resolve, request) {
      httpClient
        .get(PATH + 'indexcategorias', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubfamilias: function (resolve, request) {
      httpClient
        .get(PATH + 'indexsubfamilias', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
