<template>
  <div v-if="user">
    <CardFormItem title="Nombre" :description="user.name" />
    <CardFormItem title="Usuario de acceso" :description="user.username" />
    <CardFormItem title="Email" :description="user.email" />
    <CardFormItem
      v-if="user.cooperativa"
      title="Nombre cooperativa "
      :description="
        user.cooperativa.CODIGO + '-' + user.laboratorio.ABREVIATURA
      "
    />
    <CardFormItem
      v-if="user.laboratorio"
      title="Nombre laboratorio"
      :description="
        user.laboratorio.COD_LABORATORIO + '-' + user.laboratorio.NOMBRE
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import usuarios from '@/api/usuarios.api';

export default {
  name: 'BasicInformation',
  extends: usuarios,
  data() {
    return {
      basic: {
        cooperativa: '',
        userName: '',
      },
      user: null,
    };
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  created() {
    this.basic.cooperativa = this.getCoop;
    this.basic.userName = this.getUser.username;
    this.loadItems();
  },
  methods: {
    loadItems: function () {
      let loadPromise = new Promise((resolve) => {
        this.getUsuarios(resolve, { id: this.getUser.id });
      });
      loadPromise.then((data) => {
        this.user = [];
        this.user = data[0];
      });
    },
  },
};
</script>

<style scoped></style>
