<template>
  <div class="dropdown--container">
    <dropdown text="Todos las categorías" icon="icon-chevron-down-outline">
      <dropdown-item icon="icon-alert-triangle-outline" text="Categoría 1" />
      <dropdown-item icon="icon-archive-outline" text="Categoría 2" />
      <dropdown-item icon="icon-award-outline" text="Categoría 3" />
    </dropdown>

    <dropdown text="Todos los tipos" icon="icon-chevron-down-outline">
      <dropdown-item icon="icon-alert-triangle-outline" text="Tipo 1" />
      <dropdown-item icon="icon-archive-outline" text="Tipo 2" />
      <dropdown-item icon="icon-award-outline" text="Tipo 3" />
    </dropdown>

    <dropdown text="Todos los proveedores" icon="icon-chevron-down-outline">
      <dropdown-item icon="icon-alert-triangle-outline" text="Proveedor 1" />
      <dropdown-item icon="icon-archive-outline" text="Proveedor 2" />
      <dropdown-item icon="icon-award-outline" text="Proveedor 3" />
    </dropdown>
  </div>
</template>

<script>
export default {
  name: 'SelectorResumen',
};
</script>

<style lang="scss" scoped>
.dropdown--container {
  display: flex;
  margin-right: 100px;
  gap: 60px;
  color: var(--fc-brand-02);
}
</style>
