<template>
  <div class="graph-item">
    <SimpleBullet :width="375" :height="12" :percent="percent * 100.0" />
    <div>{{ title }}</div>
  </div>
</template>

<script>
import SimpleBullet from 'frontendcommon/src/components/charts/svg/SimpleBullet';

export default {
  name: 'ComprasGraph',
  components: {
    SimpleBullet,
  },
  props: {
    title: String,
    percent: Number,
  },
};
</script>

<style>
.graph-item {
  display: flex;
  flex-direction: row;
  gap: 13px 33px;
}
</style>
