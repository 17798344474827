import axios from 'axios';
import store from '../store/index.js';
const httpClient = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Content-Type': 'application/json;charset=utf-8',
  },
});

const requestInterceptor = (config) => {
  if (store.getters.getAccessToken != null) {
    config.headers['Authorization'] = 'Bearer ' + store.getters.getAccessToken;
  }
  if (config.method === 'post') {
    if (typeof config.data === 'undefined') {
      config.data = {
        cooperativa: store.getters.getCoop,
      };
    } else {
      config.data = {
        ...config.data,
        cooperativa: store.getters.getCoop,
      };
    }
  } else if (config.method === 'get') {
    config.url = config.url.replace('$coop', store.getters.getCoop);
  }
  return config;
};

const errorInterceptor = (error) => {
  if (error.response.status == 401) {
    if (store.getters.isAuthenticated) {
      store.dispatch('destroyStore');
      window.location.href = process.env.BASE_URL;
      throw new Error('No autorizado');
    }
  }
  return Promise.reject(error);
};
// Interceptor for responses
const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      // yay!
      break;
    // any other cases
    default:
    // default case
  }

  return response;
};

httpClient.interceptors.request.use(requestInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
