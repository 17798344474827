<template>
  <div class="data-card" @click="handleClick">
    <div class="data-card-header">
      <div class="data-card-header-title">{{ title }}</div>
      <div class="data-card-header-date">
        Datos actualizados a {{ dataInfo }}
      </div>
    </div>
    <div class="data-card-content">
      <div v-if="euros" class="data-card-euros">
        {{ $n(euros, 'currency') }}
      </div>
      <div class="data-card-info-title text-uppercase">cumplimiento</div>
      <div class="data-card-info">
        <SimpleBullet
          :width="380"
          :height="26"
          :color="checkColor(percent)"
          :percent="percent * 100"
        />
        <div class="data-card-info-percent">
          {{ $n(percent, 'percentDecimal') }}
        </div>
      </div>

      <div v-if="labs && Object.keys(labs).length > 0">
        <div v-for="lab in labs" :key="lab.title" class="lab-card">
          <img v-if="lab.img" class="lab-img" :src="lab.img" />
          <div v-else class="lab-img" :src="lab.img" />
          <div class="lab-content">
            <div class="lab-title text-uppercase">{{ lab.title }}</div>
            <div class="lab-data">
              <div>{{ lab.euros }}€ a PVL</div>
              <span></span>
              <div>{{ lab.uds }} uds</div>
            </div>
            <div class="lab-percent">{{ lab.percent }}%</div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="data-card-info-title text-uppercase">Beneficio</div>
        <div class="data-card-info-epp-graphs">
          <ComprasGraph title="Especialidad" :percent="percentEspe" />
          <ComprasGraph title="Parafarmacia" :percent="percentPara" />
          <ComprasGraph title="Publicitarios" :percent="percentPubli" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComprasGraph from '@/components/compras/ComprasGraph.vue';
import SimpleBullet from 'frontendcommon/src/components/charts/svg/SimpleBullet';

export default {
  name: 'ComprasCard',
  components: {
    ComprasGraph,
    SimpleBullet,
  },
  props: {
    onClick: Function,
    title: String,
    dataInfo: String,
    euros: String,
    percent: Number,
    percentEspe: Number,
    percentPara: Number,
    percentPubli: Number,
    labs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    checkColor(percent) {
      if (percent >= 0.8) {
        return '#75B84E';
      } else if (percent < 0.8 && percent >= 0.7) {
        return '#E3B428';
      } else return '#E72D51';
    },
    handleClick() {
      if (typeof this.onClick === 'function') {
        this.onClick();
      }
    },
  },
};
</script>

<style>
.data-card {
  width: 541px;
  background: #ffff;
  border: 1px solid rgba(197, 199, 205, 0.3);
  border-radius: 6px;
}
.data-card-header {
  padding: 18px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  border-bottom: 1.5px solid rgba(197, 199, 205, 0.3);
}
.data-card-header-title {
  font-size: 18px;
}
.data-card-header-date {
  font-size: 10px;
  opacity: 50%;
}
.data-card-content {
  padding: 20px;
}
.data-card-euros {
  font-size: 40px;
  margin-top: 23px;
  margin-bottom: 30px;
}
.data-card-info {
  display: flex;
  justify-content: space-between;
}
.data-card-info-title {
  font-size: 9px;
  font-weight: bold;
  margin-bottom: 20px;
}
.data-card-info-percent {
  font-size: 24px;
  margin-left: 18px;
  margin-bottom: 32px;
}
.data-card-info-epp-graphs {
  margin-bottom: 20px;
}

.lab-card {
  width: 100%;
  height: 115px;
  display: flex;
  flex-direction: row;
}
.lab-img {
  object-fit: scale-down;
  width: 115px;
  height: 115px;
  flex: 1;
  background-color: transparent;
  border: none;
}
.lab-content {
  flex: 3;
  padding: 22px 16px;
}
.lab-title {
  font-size: 9px;
  font-weight: 600;
}
.lab-data {
  display: flex;
  flex-direction: row;
  font-size: 23px;
  margin-top: 15px;
  margin-bottom: 7px;
}
.lab-data span {
  border-left: 1.5px solid rgba(197, 199, 205, 0.5);
  margin: 0 15px;
}
.lab-percent {
  font-size: 10px;
  color: #737373;
}
</style>
