<template>
  <div class="dudas-card">
    <div class="right">
      <h1>Resuelve tus dudas</h1>
      <p>Contacta ahora con un experto y lorem ipsum dolor sit.</p>
      <b-button variant="secondary" size="lg" class="boton">
        Contacta ahora</b-button
      >
    </div>
    <div class="left">
      <div class="circle"></div>
      <img src="../assets/farmaceutica.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactnowCard',
};
</script>

<style lang="scss" scoped>
.dudas-card {
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  height: 283px;
  background-color: var(--fc-brand-01-soft);
}
.dudas-card .right {
  flex: 3;
  width: 50%;
  margin-top: 86px;
  margin-left: 83px;
}
.dudas-card .right h1 {
  flex: 3;
}
.dudas-card .left {
  position: relative;
  display: flex;
  justify-content: end;
  width: 50%;
}
.dudas-card .left .circle {
  width: 365px;
  height: 365px;
  position: absolute;
  border-radius: 50%;
  background-color: var(--fc-brand-01-dark);
  margin-right: 123px;
  margin-top: 32px;
}
.dudas-card .left img {
  position: absolute;
  /* right: 0; */
}
</style>
