<template>
  <BaseView :title="title">
    <template #content>
      <div>
        <div class="cards-title text-uppercase">beneficio</div>
        <div class="cards">
          <div>
            <compras-card
              :on-click="redirect"
              title="Acumulado anual"
              :euros="datosCompras.beneficioAnual.beneficioTotal"
              :percent="datosCompras.beneficioAnual.cumplimiento"
              :data-info="datosCompras.fechaActulizacion"
              :percent-espe="datosCompras.beneficioAnual.percentEspe"
              :percent-para="datosCompras.beneficioAnual.percentPara"
              :percent-publi="datosCompras.beneficioAnual.percentPubli"
            />
          </div>
          <div>
            <compras-card
              :on-click="redirect"
              :title="datosCompras.beneficioMesCerrado.title"
              :euros="datosCompras.beneficioMesCerrado.beneficioTotal"
              :percent="datosCompras.beneficioMesCerrado.cumplimiento"
              :data-info="datosCompras.fechaActulizacion"
              :percent-espe="datosCompras.beneficioMesCerrado.percentEspe"
              :percent-para="datosCompras.beneficioMesCerrado.percentPara"
              :percent-publi="datosCompras.beneficioMesCerrado.percentPubli"
            />
          </div>
        </div>

        <!-- <div class="cards-title text-uppercase">genéricos</div>
        <div class="cards">
          <div v-for="generico in datosCompras.genericos" :key="generico.title">
            <compras-card
              :title="generico.title"
              :data-info="generico.dataInfo"
              :percent="generico.percent"
              :labs="generico.labs"
            />
          </div>
        </div> -->
      </div>
    </template>
  </BaseView>
</template>

<script>
import ComprasGraph from '@/components/compras/ComprasGraph.vue';
import ComprasCard from '@/components/compras/ComprasCard.vue';
import homeApi from '@/api/home.api';
import { mapGetters } from 'vuex';

export default {
  name: 'Compras',
  components: {
    ComprasCard,
    ComprasGraph,
  },
  extends: homeApi,
  data() {
    return {
      datosCompras: [],
      title: 'Compras',
    };
  },
  computed: {
    ...mapGetters('farmacias', ['getSelectedFarmacia']),
  },
  watch: {
    getSelectedFarmacia: {
      immediate: true,
      handler(newFarmacia) {
        this.loadItems();
      },
    },
  },
  async mounted() {
    this.loadItems();
  },
  methods: {
    redirect() {
      this.$router.push('/compras/resumen');
    },
    loadItems() {
      let datosComprasPromise = new Promise((resolve) => {
        this.getDatosCompras(resolve, this.request);
      });
      datosComprasPromise.then((data) => {
        this.datosCompras = [];
        this.datosCompras = data;
      });
    },
  },
};
</script>

<style>
.layout {
  padding: 53px 20px;
}
.compras-title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 103px;
}

.cards {
  display: flex;
  gap: 0 78px;
  margin-bottom: 44px;
}
.cards-title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 27px;
}
</style>
