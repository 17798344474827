import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let isLogged = store.getters.isAuthenticated;
  if (to.meta.requiresAuth && !isLogged) {
    next({
      path: '/login',
    });
  } else if (to.meta.isAuth && isLogged) {
    next({
      path: '/',
    });
  } else next();
});

export default router;
