<template>
  <BaseView :title="title">
    <template #content>
      <div class="resumen--container">
        <pageTitle
          :title="'Resumen'"
          arrow-back
          arrow-back-to="/compras"
          class="mb-4"
        />
        <selector-resumen />
      </div>
      <tabla-dto-proveedor />
      <tabla-dto-articulo />
      <ContactNowCard />
    </template>
  </BaseView>
</template>

<script>
import TablaDtoProveedor from '../../components/compras/resumen/TablaDtoProveedor';
import TablaDtoArticulo from '../../components/compras/resumen/TablaDtoArticulo';
import SelectorResumen from '../../components/compras/resumen/SelectorResumen';
import ContactNowCard from '../../components/ContactNowCard.vue';

export default {
  name: 'Resumen',
  components: {
    TablaDtoProveedor,
    TablaDtoArticulo,
    SelectorResumen,
    ContactNowCard,
  },
  data() {
    return {
      title: 'Compras',
      loading: true,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scope>
.resumen--container {
  display: flex;
  justify-content: space-between;
  right: 20px;
}
</style>
