<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3 v-if="!esNuevo">{{ titleEditar }}</h3>
        <h3 v-else>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <card-form :style="combinedStyles" title="Informacion básica">
          <template>
            <card-form-item title="Código">
              <template #description>
                <input-box v-model="itemMod.codigo" v-bind="form.codigo" />
              </template>
            </card-form-item>
            <card-form-item title="Descripción">
              <template #description>
                <input-box
                  v-model="itemMod.descripcion"
                  v-bind="form.descripcion"
                />
              </template>
            </card-form-item>
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import usuarios from '@/api/usuarios.api';
import colors from '@/mixins/colors.js';
import CheckSelector from '../../commons/forms/CheckSelector.vue';
import MultipleSelectCustom from '../../commons/forms/MultipleSelectCustom.vue';

export default {
  name: 'ModalRoles',
  components: { MultipleSelectCustom, CheckSelector },
  extends: usuarios,
  mixins: [colors],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    esNuevo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      titleEditar: 'Editar',
      titleCrear: 'Crear',
      itemMod: {},
      form: {
        codigo: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        descripcion: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      esEstandar: true,
    };
  },
  watch: {
    item: {
      handler: function (req) {
        if (Object.keys(this.item).length > 0) {
          this.cargarDatosIniciales();
        }
      },
      immediate: true,
    },
  },
  methods: {
    cargarDatosIniciales() {
      this.itemMod = { ...this.item };
    },
    actualizarCheck: function (data) {
      console.log(data);
    },

    guardar() {
      this.cleanForm();
      if (!this.validateForm()) {
        return;
      }
      this.itemMod = {
        ...this.itemMod,
      };
      if (this.esNuevo) {
        let createPromise = new Promise((resolve) => {
          this.createRoles(resolve, this.itemMod);
        });
        createPromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      } else {
        let updatePromise = new Promise((resolve) => {
          this.updateRoles(resolve, this.itemMod, this.itemMod.id_rol);
        });
        updatePromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      }
    },

    validateForm() {
      let validado = true;
      if (!this.itemMod.descripcion === '') {
        this.form.descripcion.hasError = true;
        this.form.descripcion.hasValidation = true;
        this.form.descripcion.errors.push({
          show: true,
          message: this.$t('error.obligatorio.default'),
        });
        validado = false;
      }
      return validado;
    },
    cleanForm: function () {
      this.form = {
        descripcion: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scope>
.form {
  overflow: visible;
}
</style>
