<template>
  <div class="select-custom">
    <div class="select-custom-label-container" @click="collapse = !collapse">
      <div class="select-custom-icon-text">
        <span :class="icon"></span>
        <p>{{ name }}</p>
      </div>
      <span
        class="icon-chevron-down-outline"
        :class="{ open: collapse }"
      ></span>
    </div>

    <div
      v-if="collapse"
      ref="scrollContainer"
      class="select-custom-options"
      @scroll="handleScroll"
    >
      <div v-if="search" class="multiple-select-input-search">
        <input v-model="filterText" type="text" placeholder="Buscar..." />
        <span data-v-7bda208a="" class="icon icon-search-outline"></span>
      </div>
      <div
        v-for="(option, index) in filteredOptions"
        :key="index"
        class="select-custom-option"
        :class="{ selected: option.value === selected }"
        @click="selectOption(option)"
      >
        {{ option.name }}
      </div>
      <div v-if="isLoading" class="loading-message">Cargando...</div>
    </div>
  </div>
</template>

<script>
import customvmodelMixin from '@/mixins/custom.vmodel.js';

export default {
  name: 'SelectCustom',
  mixins: [customvmodelMixin],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
    },
    selected: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    search: {
      type: Boolean,
      default: false,
    },
    loadMethod: {
      type: Function,
    },
    paginate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapse: false,
      name: '',
      filterText: '',
      isLoading: false,
      page: 1,
      localOptions: [],
    };
  },
  computed: {
    filteredOptions() {
      if (this.search && this.filterText) {
        if (this.paginate) {
          this.isLoading = true;
          this.page = 1;
          let request = {
            page: this.page,
            search: this.filterText,
          };
          this.loadData(request);
        }
        return this.options.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.filterText.toLowerCase()) != -1
          );
        });
      }
      return this.options;
    },
  },
  watch: {
    localOptions(newOptions) {
      let datosFiltrados = newOptions.filter((nuevoDato) => {
        return !this.options.some(
          (elemento) => elemento.value === nuevoDato.value
        );
      });
      datosFiltrados.forEach((item) => {
        this.options.push(item);
      });

      this.$emit('input', this.options);

      // this.options = [...datosFiltrados];
      this.isLoading = false;
    },
    options() {
      this.options.map((e) => {
        if (e.value == this.selected) {
          e.selected = true;
          this.name = e.name;
          return e;
        } else if (e.selected) {
          this.name = e.name;
          return e;
        }
      });
      if ((this.name == '') & (this.options.length > 0)) {
        this.name = this.options[0].name;
        this.options[0].selected = true;
      }
    },
  },
  created() {
    this.name = this.label;
    if (this.paginate) {
      this.isLoading = true;
      this.loadData();
    }
    this.options.map((e) => {
      if (e.value == this.selected) {
        e.selected = true;
        this.name = e.name;
        return e;
      } else if (e.selected) {
        this.name = e.name;
        return e;
      }
    });
    if ((this.name == '') & (this.options.length > 0)) {
      this.name = this.options[0].name;
      this.options[0].selected = true;
    }
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    async loadData(data) {
      this.localOptions = await this.loadMethod(data);
    },
    selectOption: function (option) {
      this.collapse = false;
      this.name = option.name;
      this.options.map((item) => {
        if (item.value == option.value) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      });
      this.$emit('selected', option);
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.collapse = false;
      }
    },
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollTop = scrollContainer.scrollTop;
      const scrollHeight = scrollContainer.scrollHeight;
      const clientHeight = scrollContainer.clientHeight;
      // console.log("SCROLLL", scrollTop, clientHeight, scrollHeight, scrollContainer)
      // console.log("scrollTop + clientHeight >= scrollHeight ",scrollTop + clientHeight >= scrollHeight );
      // console.log("!this.isLoading",!this.isLoading);
      // console.log("!this.paginate",this.paginate);

      if (
        scrollTop + clientHeight >= scrollHeight - 1 &&
        !this.isLoading &&
        this.paginate
      ) {
        this.isLoading = true;
        this.page += 1;
        let request = {
          page: this.page,
          search: this.filterText,
        };
        this.loadData(request);
        this.$emit('loadMoreData');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-custom {
  position: relative;
  height: 45px;
  padding: 0 15px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .select-custom-label-container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  p {
    font-size: 13px;
    line-height: 16px;
    margin: 0;
    font-weight: 500;
    color: var(--fc-brand-03);
  }

  .multiple-select-input-search {
    width: 100%;
    position: relative;

    input {
      width: 100%;
      height: 30px;
      padding: 0 30px 0 15px;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      box-shadow: none;
      font-size: 13px;
      line-height: 20px;
      color: #3d3f49;
    }

    span {
      position: absolute;
      top: 7px;
      right: 10px;
    }
  }

  .select-custom-icon-text {
    display: flex;
    align-items: center;

    p {
      margin: 0 10px 0 5px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: var(--fc-brand-03);
    }

    span {
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      line-height: 15px;
      color: var(--fc-brand-03);
    }
  }

  .icon-chevron-down-outline {
    cursor: pointer;
    font-size: 22px;
    line-height: 22px;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    &.open {
      transform: rotate(180deg);
    }
  }

  .select-custom-options {
    z-index: 999;
    position: absolute;
    top: 50px;
    left: 0;
    max-width: 300px;
    max-height: 300px;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    box-shadow: 0px 3px 10px rgba(216, 213, 213, 0.25);
    overflow-y: scroll;

    .selected {
      background-color: var(--fc-brand-01);
      color: #fff;
    }

    .select-custom-option {
      cursor: pointer;
      width: 100%;
      padding: 9px 12px;
      border-radius: 6px;
      -webkit-transition: 0.3s;
      transition: 0.3s;

      &:hover {
        color: #fff;
        background-color: var(--fc-brand-01);
      }
    }
  }
}
</style>
