<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3 v-if="!esNuevo">{{ titleEditar }}</h3>
        <h3 v-else>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <card-form :style="combinedStyles" title="Información básica">
          <template>
            <card-form-item title="Farmacia">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_farmacia"
                    :filter="true"
                    :options="opcionesFarmacia"
                    v-bind="form.nombre_farmacia"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item title="Tipo">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.tipo"
                    :options="tiposOpciones"
                    :filter="true"
                    placeholder="Seleccione un tipo"
                    v-bind="form.tipo"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item v-if="itemMod.tipo === 'L'" title="Laboratorio">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_laboratorio"
                    :filter="true"
                    :options="opcionesLaboratorio"
                    v-bind="form.nombre_laboratorio"
                    :errors="form.nombre_laboratorio.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item v-if="itemMod.tipo === 'C'" title="Categoría">
              <template #description>
                <div>
                  {{ itemMod.codigo_categoria }}
                  <select-box
                    v-model="itemMod.codigo_categoria"
                    :filter="true"
                    :options="opcionesCategoria"
                    v-bind="form.codigo_categoria"
                    :errors="form.codigo_categoria.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item v-if="itemMod.tipo === 'P'" title="Código Producto">
              <template #description>
                <div>
                  <input-box
                    v-model="itemMod.codigo_producto"
                    :type="number"
                    v-bind="form.codigo_producto"
                    :errors="form.codigo_producto.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item title="Descuento">
              <template #description>
                <input-box
                  v-model="itemMod.dto"
                  :type="number"
                  v-bind="form.dto"
                  :errors="form.nombre_laboratorio.errors"
                />
              </template>
            </card-form-item>
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import farmacias from '@/api/farmacias.api';
import farmaciaDescuento from '@/api/farmaciaDescuento.api';
import proveedores from '@/api/proveedores.api';
import laboratorios from '@/api/laboratorios.api';
import catalogoSellout from '@/api/catalogoSellout.api';
import colors from '@/mixins/colors.js';
import CheckSelector from '../../commons/forms/CheckSelector.vue';
import MultipleSelectCustom from '../../commons/forms/MultipleSelectCustom.vue';

export default {
  name: 'ModalFarmacias',
  components: { MultipleSelectCustom, CheckSelector },
  extends: farmacias,
  laboratorios,
  farmaciaDescuento,
  mixins: [
    colors,
    proveedores,
    laboratorios,
    farmacias,
    farmaciaDescuento,
    catalogoSellout,
  ],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    esNuevo: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      titleEditar: 'Editar',
      titleCrear: 'Crear',
      itemMod: {},
      opcionesFarmacia: [],
      opcionesLaboratorio: [],
      opcionesCategoria: [],
      tiposOpciones: [
        { text: 'Farmacia (F)', value: 'F' },
        { text: 'Laboratorio (L)', value: 'L' },
        { text: 'Categoría (C)', value: 'C' },
        { text: 'Producto (P)', value: 'P' },
      ],
      form: {
        nombre_farmacia: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        codigo_categoria: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre_laboratorio: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        dto: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        tipo: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        codigo_producto: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      esEstandar: true,
    };
  },
  watch: {
    'itemMod.codigo_categoria': function (newVal) {
      console.log('Nuevo valor de codigo_categoria:', newVal);
    },
  },
  mounted() {
    this.loadFiltros();
    console.log('Mounted: Opciones y datos iniciales cargados');
  },

  methods: {
    loadDatosFarmacia() {
      if (this.id) {
        let createPromise = new Promise((resolve) => {
          this.showFarmaciaDescuento(resolve, this.id);
        });
        createPromise.then((data) => {
          if (data) {
            this.itemMod = data;
            if (this.itemMod.laboratorio) {
              this.itemMod.id_laboratorio =
                this.itemMod.laboratorio.id_laboratorio;
            }
            if (this.itemMod.categoria) {
              this.itemMod.codigo_categoria = String(
                this.itemMod.categoria.codigo_categoria
              );
            }
            console.log(
              'Valor inicial de codigo_categoria:',
              this.itemMod.codigo_categoria
            );
            this.itemMod.codigo_producto = this.itemMod.cn;

            console.log('Valor inicial de catt:', this.itemMod.categoria);
          }
        });
      }
    },

    guardar() {
      this.cleanForm();

      if (!this.validateForm()) {
        return;
      }

      if (this.itemMod.tipo === 'C') {
        this.itemMod.id_laboratorio = null;
      }

      if (this.itemMod.tipo === 'L') {
        this.itemMod.codigo_categoria = null;
      }

      const datosAEnviar = {
        id_farmacia: String(this.itemMod.id_farmacia),
        tipo: this.itemMod.tipo,
        id_laboratorio: this.itemMod.id_laboratorio
          ? String(this.itemMod.id_laboratorio)
          : null,
        id_categoria: this.itemMod.codigo_categoria || null,
        cn: this.itemMod.codigo_producto || '',
        dto: this.itemMod.dto || '',
      };

      console.log(datosAEnviar);

      if (this.esNuevo) {
        let createPromise = new Promise((resolve) => {
          this.createFarmaciaDescuento(resolve, datosAEnviar);
        });
        createPromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      } else {
        let updatePromise = new Promise((resolve) => {
          this.updateFarmaciaDescuento(
            resolve,
            datosAEnviar,
            this.itemMod.id_farmacia_descuento
          );
        });
        updatePromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      }
    },

    async loadFiltros() {
      try {
        const farmaciasData = await new Promise((resolve) =>
          this.getFarmacias(resolve)
        );
        this.opcionesFarmacia = farmaciasData.map((item) => ({
          text: item.nombre,
          value: item.id_farmacia,
        }));

        const laboratoriosData = await new Promise((resolve) =>
          this.getLaboratorios(resolve)
        );
        this.opcionesLaboratorio = laboratoriosData.map((item) => ({
          text: item.nombre,
          value: item.id_laboratorio,
        }));

        // Obtener categorías y limpiar datos innecesarios
        const categoriasData = await new Promise((resolve) =>
          this.getCategorias(resolve)
        );
        console.log('Datos crudos de categorías:', categoriasData);

        this.opcionesCategoria = categoriasData.map((item) => ({
          text: item.categoria,
          value: String(item.id_categoria),
        }));

        console.log(
          'Opciones procesadas de categorías:',
          this.opcionesCategoria
        );
      } catch (error) {
        console.error('Error al cargar filtros:', error);
      }
      this.loadDatosFarmacia();
    },

    validateForm() {
      let validado = true;

      if (this.itemMod.tipo === 'L' && !this.itemMod.id_laboratorio) {
        this.form.nombre_laboratorio.hasError = true;
        this.form.nombre_laboratorio.errors.push({
          show: true,
          message: 'Seleccione laboratorio',
        });
        validado = false;
      }

      if (this.itemMod.tipo === 'C' && !this.itemMod.codigo_categoria) {
        this.form.codigo_categoria.hasError = true;
        this.form.codigo_categoria.errors.push({
          show: true,
          message: 'Seleccione categoría',
        });
        validado = false;
      }

      if (this.itemMod.tipo === 'P' && !this.itemMod.codigo_producto) {
        this.form.codigo_producto.hasError = true;
        this.form.codigo_producto.errors.push({
          show: true,
          message: 'Introduce código del producto',
        });
        validado = false;
      }

      return validado;
    },

    cleanForm() {
      this.form = {
        descripcion: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        codigo_producto: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        codigo_categoria: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre_farmacia: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre_laboratorio: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        tipo: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scope>
.form {
  overflow: visible;
}
.form-error-box.min-height {
  min-height: 10px !important;
}
</style>
