<template>
  <div>
    <div :style="colorStyles">
      <DataTableNafScroll
        :fields="columns"
        :items="transfer"
        :per-page="transfer.length"
        @onRowClick="handleClick"
      >
        <template #cell(cliente)="{ item }">
          <div>
            <span v-if="item.farmacia_cooperativa" class="mb-2 pt-2">{{
              item.farmacia_cooperativa.farmacia_unnefar.NOMBRE
            }}</span>
            <span v-else>-</span>
          </div>
        </template>
        <template #cell(nif)="{ item }">
          <span v-if="item.farmacia_cooperativa">{{
            item.farmacia_cooperativa.farmacia_unnefar.NIF
          }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(cooperativa)="{ item }">
          <span v-if="item.cooperativa">{{
            item.cooperativa.ABREVIATURA
          }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(provincia)="{ item }">
          <span v-if="item.farmacia_cooperativa">{{
            item.farmacia_cooperativa.provincia.DESCRIPCION
          }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(idTransferLab)="{ item }">
          <span v-if="item.IDTRANSFERLAB">{{ item.IDTRANSFERLAB }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(tipo)="{ item }">
          <span v-if="item.TIPO">{{ item.TIPO | formatTipoTrDol }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(fechaini)="{ item }">
          <span v-if="item.FECHAINICOMPRO">{{
            item.FECHAINICOMPRO | formatDate
          }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(fechafin)="{ item }">
          <span v-if="item.FECHAFINCOMPRO">{{
            item.FECHAFINCOMPRO | formatDate
          }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(fecharecepcion)="{ item }">
          <span v-if="item.FECHARECEPCION">{{
            item.FECHARECEPCION | formatDate
          }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(fechaMov)="{ item }">
          <span v-if="item.FECHA_PRIMER_MOV">{{
            item.FECHA_PRIMER_MOV | formatDate
          }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(udContratadas)="{ item }">
          <span>{{ item.UD_SOLICITADAS }}</span>
        </template>
        <template #cell(udServidas)="{ item }">
          <span>{{ item.UD_SERVIDAS }}</span>
        </template>
        <template #cell(grupal)="{ item }">
          <span>{{ item.GRUPAL }}</span>
        </template>
        <template #cell(codgrupocop)="{ item }">
          <span v-if="item.CODGRUPOCOP">{{ item.CODGRUPOCOP }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(importeBruto)="{ item }">
          <span>{{ item.IMPORTE_BRUTO | formatPrice }}</span>
        </template>
        <template #cell(importeNeto)="{ item }">
          <span>{{ item.IMPORTE_NETO | formatPrice }}</span>
        </template>
        <template #cell(situacion)="{ item }">
          <span
            :style="{
              color:
                item.SITUACION === 'SERVIDO'
                  ? colors.success
                  : item.SITUACION === 'EN CURSO'
                    ? colors.warning
                    : colors.danger,
            }"
          >
            {{ item.SITUACION }}
          </span>
        </template>
      </DataTableNafScroll>
    </div>
    <modal-detalle-linea
      v-if="showModal"
      :transfer="itemSelected"
      @close="close"
    />
  </div>
</template>

<script>
import ModalDetalleLinea from './ModalDetalleLinea.vue';
import CardCabecera from './CardCabecera.vue';
import colors from '@/mixins/colors.js';
import { formatPrice, formatDate, formatTipoTrDol } from '@/filters/formats';
export default {
  name: 'CardSearchCabecera',
  components: {
    ModalDetalleLinea,
    CardCabecera,
  },
  filters: {
    formatPrice,
    formatDate,
    formatTipoTrDol,
  },
  mixins: [colors],
  props: ['transfer'],
  data() {
    return {
      showModal: false,
      itemSelected: null,
      columns: [
        {
          key: 'cliente',
          label: 'Cliente',
          sortable: false,
        },
        {
          key: 'nif',
          label: 'NIF',
          sortable: false,
        },
        {
          key: 'cooperativa',
          label: 'Cooperativa',
          sortable: false,
        },
        {
          key: 'provincia',
          label: 'Provincia',
          sortable: false,
        },
        {
          key: 'idTransferLab',
          label: 'Identificador laboratorio',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'tipo',
          label: 'Tipo',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'fechaini',
          label: 'Fecha inicio de compra',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'fechafin',
          label: 'Fecha fin de compra',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'fecharecepcion',
          label: 'Fecha recepción',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'fechaMov',
          label: 'Fecha primer movimiento',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'udContratadas',
          label: 'Unidades contratadas',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'udServidas',
          label: 'Unidades servidas',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'grupal',
          label: 'Grupal',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'codgrupocop',
          label: 'Código grupo',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'importeBruto',
          label: 'Importe bruto',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'importeNeto',
          label: 'Importe neto',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'situacion',
          label: 'Situación',
          sortable: false,
        },
      ],
    };
  },

  methods: {
    handleClick(item) {
      console.log(item);
      this.itemSelected = item;
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
