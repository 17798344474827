import store from '../store';

const accessToken = store.getters.getAccessToken;

import httpClient from './httpClient';

const PATH = '/proveedor/';

export default {
  methods: {
    getProveedores: function (resolve, request) {
      httpClient

        .get(PATH + 'index', request)

        .then((response) => {
          resolve(response.data);
        })

        .catch((error) => {
          console.log(error);
        });
    },

    updateProveedor: function (resolve, request, id) {
      httpClient

        .post(PATH + `update/${id}`, request)

        .then((response) => {
          resolve(response.data);
        })

        .catch((error) => {
          console.log(error);
        });
    },

    createProveedor: function (resolve, request) {
      httpClient

        .post(PATH + 'create', request)

        .then((response) => {
          resolve(response.data);
        })

        .catch((error) => {
          console.log(error);
        });
    },

    deleteProveedor: function (resolve, request, id) {
      httpClient

        .delete(PATH + `destroy/${id}`, request)

        .then((response) => {
          resolve(response.data);
        })

        .catch((error) => {
          console.log(error);
        });
    },

    getProveedoresSellout: function (resolve, request) {
      httpClient

        .get('prov-sellout/' + 'index', request)

        .then((response) => {
          resolve(response.data);
        })

        .catch((error) => {
          console.log(error);
        });
    },
  },
};
