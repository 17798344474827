<template>
  <widget :min-height="false" :small-padding="true">
    <template #header-left>
      <h5 class="mayus">{{ title }}</h5>
    </template>
    <template #header-right>
      <span class="icon" :class="icon" @click="$emit('click-icon', id)"></span>
    </template>
    <template>
      <h3>
        <span class="kpi-value">{{ description }}</span>
      </h3>
      <h8 class="footer">{{ extra }}</h8>
    </template>
  </widget>
</template>

<script>
export default {
  name: 'CardWidget',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    extra: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
@import 'frontendcommon/src/scss/config/design_token';
.icon {
  color: var(--fc-brand-01);
  font-size: 20px;
  margin-bottom: 10px;
  display: inline-block;
}
.mayus {
  text-transform: uppercase;
}
.footer {
  display: block;
  margin-bottom: 4px;
  color: $dark-grey;
}
</style>
