import store from '../store';

const accessToken = store.getters.getAccessToken;
import httpClient from './httpClient';

const PATH = '/farmacia/';

export default {
  methods: {
    getFarmacias: function (resolve, request) {
      httpClient
        .get(PATH + 'index', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    findFarmacia: function (resolve, id) {
      httpClient
        .get(PATH + 'show/' + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createFarmacia: function (resolve, request) {
      httpClient
        .post(PATH + 'create', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateFarmacia: function (resolve, request, id) {
      httpClient
        .post(PATH + 'update/' + id, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteFarmacia: function (resolve, request, id) {
      httpClient
        .delete(PATH + 'destroy/' + id, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
