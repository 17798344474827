<template>
  <dropdown-filter
    v-if="isUnnefar"
    title="Filtrar por"
    :filter-to-show="cooperativa != null ? 'Cooperativa: ' + cooperativa : ''"
    :disable-reset="true"
    @apply-filters="applyFilters"
  >
    <template>
      <select-box
        v-model="cooperativa"
        background="dark"
        label="Seleccione cooperativa"
        :options="opcionesCoop"
      />
    </template>
  </dropdown-filter>
</template>
<script>
import home from '@/api/home.api';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'FiltroCoopUnne',
  extends: home,
  data() {
    return {
      opcionesCoop: [],
      cooperativa: null,
      isUnnefar: false,
    };
  },
  watch: {
    '$store.getters.isUnne': {
      immediate: true,
      handler() {
        if (
          this.getUser().cooperativa == null ||
          this.getUser().cooperativa == '' ||
          this.getUser().cooperativa.cooperativa == '99'
        ) {
          this.isUnnefar = true;
          this.loadCooperativas();
        }
      },
    },
    cooperativa: {
      immediate: false,
      handler() {
        if (this.cooperativa != this.getCoop()) {
          this.saveCoop(this.cooperativa);
          this.$emit('update');
        }
      },
    },
  },
  created() {
    this.cooperativa = this.getCoop();
  },
  methods: {
    ...mapGetters(['getCoop', 'getUser', 'isUnne']),
    ...mapActions(['saveCoop']),
    applyFilters() {},
    loadCooperativas() {
      let cooperativaPromise = new Promise((resolve) => {
        this.getCooperativasIntegrado(resolve);
      });
      cooperativaPromise.then((data) => {
        this.opcionesCoop = [];
        data.forEach((item) => {
          this.opcionesCoop.push({
            text: item.codigo + ' - ' + item.abreviatura,
            value: item.codigo,
          });
        });
      });
    },
  },
};
</script>

<style lang="scss">
#dropdown-filter {
  .inputbox-input {
    color: #fff;
  }
  .icon-funnel-outline {
    color: var(--fc-brand-03);
  }
  .inputbox-icons {
    display: none;
  }
  .selectbox.background-dark .select-filter .icon,
  .selectbox.background-dark .select-filter input {
    color: #333 !important;
  }
}
</style>
