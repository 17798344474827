<template>
  <div>
    <toast
      v-if="showToast"
      class="mt-2"
      type="success-filled"
      description="Exclusión guardada correctamente"
      @timeout="handleCloseToast"
      @close="handleCloseToast"
    />

    <card>
      <template #content>
        <b-row>
          <b-col>
            <h4>{{ title }}</h4>
          </b-col>
          <b-col class="text-right">
            <b-button variant="primary" @click="crear">{{
              $t('button.create')
            }}</b-button>
          </b-col>
        </b-row>
      </template>
      <template>
        <div v-if="items.length">
          <DataTableNaf
            :fields="columns"
            :items="items"
            :per-page="10"
            grid-cols="3fr 3fr 3fr 3fr 2fr "
          >
            <template #cell(name)="{ item }">
              <span>{{ item.name }}</span>
            </template>
            <template #cell(email)="{ item }">
              <span>{{ item.email }}</span>
            </template>
            <template #cell(id_rol)="{ item }">
              <span v-if="item.rol">{{ item.rol.descripcion }}</span>
            </template>
            <!-- <template #cell(enable)="{ item }">
                        <span v-if="item.enable" class=" icon icon-checkmark-outline"></span>
                        <span v-else></span>
                    </template> -->
            <template #cell(icons)="{ item }">
              <div class="d-flex justify-content-between">
                <div class="pr-2">
                  <span
                    class="icon icon-edit-outline"
                    @click="editar(item)"
                  ></span>
                </div>
                <div class="pr-2">
                  <span
                    class="icon icon-lock-outline"
                    @click="openResetPassword(item)"
                  ></span>
                </div>
                <div class="pr-2">
                  <span
                    class="icon icon-trash-outline"
                    @click="openConfirmacion(item)"
                  ></span>
                </div>
              </div>
            </template>
          </DataTableNaf>
        </div>
        <card-item-simple v-if="!items.length">
          <template #description>
            <div>{{ $t('sinResultados') }}</div>
          </template>
        </card-item-simple>
      </template>
    </card>
    <modal-usuarios
      v-if="showModal"
      :item="texto"
      :es-nuevo="esNuevo"
      @close="close"
      @save="loadItems"
    />
    <modal-confirmacion
      v-if="showConfirmation"
      :descripcion="$t('modal.descripcion.borrar.usuario')"
      @close="close"
      @confirm="borrar"
    />
    <modal-reset-password
      v-if="showResetPassword"
      :user="userSelected"
      @close="close"
    />
  </div>
</template>

<script>
import usuarios from '@/api/usuarios.api';
import moment from 'moment';
import ModalUsuarios from './ModalUsuarios.vue';
import colors from '@/mixins/colors.js';
import ModalResetPassword from './ModalResetPassword.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'ListadoUsuarios',
  components: {
    ModalUsuarios,
    ModalResetPassword,
  },
  extends: usuarios,
  mixins: [colors],
  data() {
    return {
      title: 'Usuarios',
      showModal: false,
      showConfirmation: false,
      showResetPassword: false,
      items: [],
      esNuevo: false,
      texto: {},
      showToast: false,
      columns: [
        {
          key: 'name',
          sortable: true,
          label: 'Nombre',
        },
        {
          key: 'email',
          sortable: true,
          label: 'Email',
        },
        {
          key: 'rol_descripcion',
          sortable: true,
          label: 'Rol',
        },
        {
          key: 'nombre_farmacia',
          sortable: true,
          label: 'Farmacia',
        },
        {
          key: 'icons',
          sortable: false,
          label: '',
          class: 'justify-content-end',
        },
      ],
      request: {},
      loading: true,
      itemToDelete: null,
      userSelected: {},
    };
  },
  watch: {
    request: function () {
      this.loadItems();
    },
    reload: function () {
      this.loadItems();
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    loadItems: function () {
      let loadPromise = new Promise((resolve) => {
        this.getUsuarios(resolve, this.request);
      });
      loadPromise.then((data) => {
        this.items = [];
        this.items = data;
      });
    },
    editar: function (item) {
      this.esNuevo = false;
      this.texto = {
        ...item,
      };
      this.showModal = true;
    },
    crear: function (event) {
      this.esNuevo = true;
      this.texto = {};
      this.showModal = true;
    },
    borrar: function (item) {
      this.showConfirmation = false;
      if (this.itemToDelete) {
        let deletePromise = new Promise((resolve) => {
          this.deleteUsuarios(resolve, item, this.itemToDelete);
        });
        deletePromise.then((data) => {
          this.loadItems();
        });
      }
    },
    close: function (event) {
      this.showConfirmation = false;
      this.showModal = false;
      this.showResetPassword = false;
    },
    openConfirmacion: function (item) {
      this.itemToDelete = item.id;
      this.showConfirmation = true;
    },
    openResetPassword: function (data) {
      this.showResetPassword = true;
      this.userSelected = data;
    },
    handleCloseToast() {
      this.showToast = false;
    },
  },
};
</script>

<style lang="scss" scope></style>
