import store from '../store';

const accessToken = store.getters.getAccessToken;
import httpClient from './httpClient';

const PATH = '/farmaciaDescuento/';

export default {
  methods: {
    getDescuentos: function (resolve, request) {
      httpClient
        .get(PATH + 'index', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showFarmaciaDescuento: function (resolve, id) {
      httpClient
        .get(PATH + 'show/' + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createFarmaciaDescuento: function (resolve, request) {
      httpClient
        .post(PATH + 'create', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateFarmaciaDescuento: function (resolve, request, id) {
      httpClient
        .post(PATH + 'update/' + id, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteFarmaciaDescuento: function (resolve, request, id) {
      httpClient
        .delete(PATH + 'destroy/' + id, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
