<template lang="">
  <BaseView :title="title" class="mb-5">
    <template #content>
      <b-container fluid>
        <b-row class="mb-3">
          <b-col>
            <listado-usuarios />
          </b-col>
        </b-row>
        <!-- <b-row class="mb-3">
                <b-col>
                    <listado-roles/>
                </b-col>
                <b-col>
                    <listado-abilities/>
                </b-col>
            </b-row> -->
      </b-container>
    </template>
  </BaseView>
</template>

<script>
import { ListadoUsuarios, ListadoRoles, ListadoAbilities } from '@/components';
import { mapGetters } from 'vuex';
export default {
  name: 'Usuarios',
  components: {
    ListadoUsuarios,
    ListadoRoles,
    ListadoAbilities,
  },
  data() {
    return {
      title: 'Usuarios',
      loading: true,
    };
  },
  methods: {
    ...mapGetters(['isCoop', 'isLab']),
  },
};
</script>

<style lang=""></style>
