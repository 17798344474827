<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center pt-5">
      <img
        class="loading-icon"
        src="@/assets/loading.gif"
        alt=""
        style="width: 150px; height: 150px"
      />
    </div>
    <div v-else id="login-page" class="d-flex">
      <div class="left-part d-flex justify-content-center align-items-center">
        <img class="logo" src="@/assets/logo.jpeg" />

        <div class="login-card">
          <span
            v-if="step > 0"
            class="arrow-back-btn icon-arrow-back-outline"
            @click="backTo"
          ></span>

          <h2 class="font-weight-[600px]">{{ $t('login.title') }}</h2>
          <h2 class="mb-4 font-italic font-weight-[600px]">
            {{ $t('login.titleSpan') }}
          </h2>
          <p class="mb-5">{{ $t('login.subtitle') }}</p>

          <div v-show="step === 0" id="step-one">
            <input-box
              v-model="credentials.username"
              autofocus
              :label="$t('login.user')"
              :has-error="form.username.hasError"
              :errors="form.username.errors"
              @keypress.enter="nextTo"
            />
            <b-button class="w-100" variant="primary" @click="nextTo">
              {{ $t('login.next') }}</b-button
            >
          </div>

          <div v-show="step !== 0" id="stepTwo">
            <input-box
              v-model="credentials.password"
              :label="$t('login.password')"
              :has-error="form.password.hasError"
              type="password"
              :errors="form.password.errors"
            />
            <b-button class="w-100" variant="primary" @click="initLogin">
              {{ $t('login.access') }}</b-button
            >
            <!-- <b-button class="w-100" variant="link" @click="showResetPassword = true">{{ $t("login.forgetPassword") }}</b-button> -->
          </div>

          <modal-confirmation
            v-model="showResetPassword"
            :title="$t('login.resetPassword')"
            :description="$t('login.descriptionPassword')"
            :disable-confirmation="false"
            :confirm-text="$t('login.reset')"
            :show-x="true"
            @confirm="resetPassword"
            @hide="showResetPassword = false"
          >
            <template #content-extra>
              <input-box
                v-model="credentials.username"
                :label="$t('login.user')"
                :has-error="form.username.hasError"
                :errors="form.username.errors"
              />
            </template>
          </modal-confirmation>
        </div>
      </div>

      <div
        class="right-part d-flex justify-content-center align-items-center vh-100"
      >
        <div class="slider-content">
          <CarouselSingle :arrows="false" :slides="slides" :indicators="false">
            <template #slide="{ slide }">
              <div class="slide">
                <object
                  :data="require(`${slide.src}`)"
                  width="74"
                  height="74"
                ></object>
                <h4>{{ slide.title }}</h4>
                <p>
                  <i> {{ slide.content }} </i>
                </p>
              </div>
            </template>
          </CarouselSingle>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CarouselSingle } from 'frontendcommon';
import auth from '@/api/auth';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',
  components: {
    CarouselSingle,
  },
  extends: auth,
  data() {
    return {
      loading: false,
      step: 0,
      showResetPassword: false,
      credentials: {
        username: '',
        password: '',
      },
      form: {
        username: {
          hasError: false,
          errors: [],
        },
        password: {
          hasError: false,
          errors: [],
        },
      },
      slides: [
        {
          src: './assets/step-1.svg',
          title: this.$t('login.carousel.title'),
          content: this.$t('login.carousel.description'),
        },
      ],
    };
  },
  created() {
    this.credentials.username = this.getUsername();
    this.isLogged = this.isAuthenticated();

    if (this.isLogged) {
      this.checkSessionStatus();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions(['saveAccessToken', 'saveUsername', 'saveUser']),
    ...mapGetters(['getUsername', 'isAuthenticated']),
    backTo: function () {
      this.showResetPassword = false;
      this.step--;
    },
    nextTo: function () {
      this.cleanForm();

      if (this.credentials.username === '') {
        this.form.username.hasError = true;
        this.form.username.errors.push({
          show: true,
          message: this.$t('login.requireUser'),
        });
      } else this.step++;
    },
    checkSessionStatus: function () {
      let sessionStatusPromise = new Promise((resolve) => {
        this.sessionStatus(resolve);
      });
      sessionStatusPromise.then((data) => {
        let user = data.user;
        if (user.length === 0) {
          console.log('usuario no encontrado');
        } else {
          this.saveUser(user);
          this.$router.push('/');
        }
      });
    },
    initLogin: function () {
      this.cleanForm();

      if (this.credentials.password === '') {
        this.form.password.hasError = true;
        this.form.password.errors.push({
          show: true,
          message: this.$t('login.requirePassword'),
        });
      } else this.callLoginApi();
    },
    cleanForm: function () {
      this.form = {
        username: {
          hasError: false,
          errors: [],
        },
        password: {
          hasError: false,
          errors: [],
        },
      };
    },
    callLoginApi: function () {
      let loginPromise = new Promise((resolve) => {
        this.login(resolve, this.credentials);
      });

      loginPromise.then((data) => {
        if (data) {
          this.saveAccessToken(data.access_token);
          this.checkSessionStatus();
          window.location.reload();
        } else {
          this.form.password.hasError = true;
          this.form.password.errors.push({
            show: true,
            message: this.$t('login.wrongLogin'),
          });
        }
      });
    },
    resetPassword: function () {
      let resetPasswordPromise = new Promise((resolve) => {
        this.resetPasswordApi(resolve, this.credentials.username);
      });
      resetPasswordPromise.then((data) => {
        if (data) {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#login-page {
  background: #ffffff;

  .left-part {
    flex-basis: 60%;
    width: 60%;
    position: relative;

    .arrow-back-btn {
      display: block;
      margin-bottom: 40px;
      cursor: pointer;
      vertical-align: sub;
      color: var(--fc-brand-03);
      font-size: 24px;
    }

    .logo {
      position: absolute;
      top: 40px;
      left: 55px;
      max-width: 180px;
    }

    .login-card {
      width: 460px;
    }

    .forget-password {
      padding: 25px;
      border: 1px solid var(--fc-brand-01);
    }
  }

  .right-part {
    flex-basis: 40%;
    width: 40%;
    background-color: var(--fc-brand-01);

    .slider-content {
      width: 270px;

      .slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        * {
          color: #fff;
          text-align: center;
        }

        h4 {
          margin: 60px 0 10px 0;
        }
      }
    }
  }
}
</style>
