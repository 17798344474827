import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getUser']),
  },
  methods: {
    isAdmin: function () {
      return this.getUser.id_rol == 1;
    },
    isTitular: function () {
      return this.getUser.id_rol == 2;
    },
    isFarmacia: function () {
      return this.getUser.id_rol == 3;
    },
    isEmpleado: function () {
      return this.getUser.id_rol == 4;
    },
  },
};
