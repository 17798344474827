export default {
  namespaced: true,
  state: {
    filtros: {
      proveedores: [],
      laboratorios: [],
      tipos: [],
      categorias: [],
      subfamilias: [],
    },
  },
  mutations: {
    setFiltros(state, { filtro, items }) {
      state.filtros[filtro] = items;
    },
  },
  actions: {
    updateFiltros({ commit }, { filtro, items }) {
      commit('setFiltros', { filtro, items });
    },
  },
  getters: {
    getFiltros: (state) => state.filtros,
  },
};
