<template>
  <div class="multiple-select-custom-subtoptions">
    <div class="multiple-select-custom-subtoptions-check">
      <div class="multiple-select-input-search">
        <input v-model="search" type="text" placeholder="Buscar..." />
        <span data-v-7bda208a="" class="icon icon-search-outline"></span>
      </div>
      <div
        v-for="option in searchSuboptions"
        :key="option.value"
        class="multiple-select-check"
      >
        <input v-model="values" type="checkbox" :value="option.value" />
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleSelectInnerWithSubOptions',
  props: ['suboptions'],
  data() {
    return {
      search: '',
      values: [],
    };
  },
  computed: {
    searchSuboptions: function () {
      return this.suboptions.filter((item) => {
        return item.text.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-select-custom-subtoptions-check {
  .multiple-select-input-search {
    width: 100%;
    position: relative;
    input {
      width: 100%;
      height: 30px;
      padding: 0 30px 0 15px;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      box-shadow: none;
      font-size: 13px;
      line-height: 20px;
      color: #3d3f49;
    }
    span {
      position: absolute;
      top: 7px;
      right: 10px;
    }
  }
  .multiple-select-check {
    display: flex;
    align-items: flex-start;
    width: 215px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #3d3f49;
    input {
      margin: 5px 5px 0 0;
    }
  }
}
</style>
