<template>
  <div id="contact-info">
    <CardFormItem title="Email" :description="email">
      <template #icon>
        <span class="icon-edit-outline" @click="showModalMail = true"></span>
      </template>
    </CardFormItem>
    <!-- <modal-change-email v-if="showModalMail" @close="showModalMail = false" /> -->
    <!-- <CardFormItem title="Teléfonos" description="jhfjhkfhdjks">
      <template v-slot:icon>
        <span class="icon-edit-outline" @click="showModalPhone = true"></span>
      </template>
    </CardFormItem>
    <modal-change-phone v-if="showModalPhone" @close="showModalPhone = false" /> -->
  </div>
</template>

<script>
import ModalChangeEmail from './ModalChangeEmail';
import ModalChangePhone from './ModalChangePhone';
import { mapGetters } from 'vuex';
export default {
  name: 'ContactInformation',
  components: { ModalChangeEmail, ModalChangePhone },
  data() {
    return {
      showModalMail: false,
      showModalPhone: false,
      email: '',
    };
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  created() {
    this.email = this.getUser.email;
  },
};
</script>

<style lang="scss" scoped>
#contact-info {
  .icon-edit-outline {
    font-size: 20px;
  }
}
</style>
