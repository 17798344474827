import FiltroCoopUnne from './FiltroCoopUnne';
import ExportExcel from './ExportExcel';

import CardItemCheckBox from './cards/CardItemCheckBox';
import CardItemSimple from './cards/CardItemSimple';
import CardWidget from './cards/CardWidget';
import CardSimpleWidget from './cards/CardSimpleWidget';

import SidebarNaf from './SidebarNaf';
import UserBoxNaf from './UserBoxNaf';
import FilterGroup from './FilterGroup';
import Calendario from './Calendario';
import DataTableNaf from './DataTableNaf';
import DataTableNafScroll from './DataTableNafScroll';
import LoaderNaf from './loader/LoaderNaf';

import ModalConfirmacion from './ModalConfirmacion';

import MultipleSelectInnerCheckLoad from './forms/multipleSelectInner/MultipleSelectInnerCheckLoad';
import MultipleSelectInnerInput from './forms/multipleSelectInner/MultipleSelectInnerInput';
import MultipleSelectInnerCheck from './forms/multipleSelectInner/MultipleSelectInnerCheck';
import MultipleSelectInnerNormal from './forms/multipleSelectInner/MultipleSelectInnerNormal';
import MultipleSelectInnerWithSubOptions from './forms/multipleSelectInner/MultipleSelectInnerWithSubOptions';
import CheckSelector from './forms/CheckSelector';
import InputCustom from './forms/InputCustom';
import SelectCustom from './forms/SelectCustom';
import MultipleSelectCustom from './forms/MultipleSelectCustom';
import CheckSelectorCollapseLoad from './forms/CheckSelectorCollapseLoad';
import CheckSelectorCollapse from './forms/CheckSelectorCollapse';

export default {
  install: function (Vue) {
    Vue.component(FiltroCoopUnne.name, FiltroCoopUnne);
    Vue.component(ExportExcel.name, ExportExcel);

    Vue.component(
      MultipleSelectInnerCheckLoad.name,
      MultipleSelectInnerCheckLoad
    );
    Vue.component(MultipleSelectInnerInput.name, MultipleSelectInnerInput);
    Vue.component(MultipleSelectInnerCheck.name, MultipleSelectInnerCheck);
    Vue.component(MultipleSelectInnerNormal.name, MultipleSelectInnerNormal);
    Vue.component(
      MultipleSelectInnerWithSubOptions.name,
      MultipleSelectInnerWithSubOptions
    );
    Vue.component(CheckSelectorCollapseLoad.name, CheckSelectorCollapseLoad);
    Vue.component(CheckSelectorCollapse.name, CheckSelectorCollapse);

    Vue.component(CheckSelector.name, CheckSelector);
    Vue.component(InputCustom.name, InputCustom);
    Vue.component(MultipleSelectCustom.name, MultipleSelectCustom);
    Vue.component(SelectCustom.name, SelectCustom);

    Vue.component(CardWidget.name, CardWidget);
    Vue.component(CardSimpleWidget.name, CardSimpleWidget);
    Vue.component(CardItemCheckBox.name, CardItemCheckBox);
    Vue.component(CardItemSimple.name, CardItemSimple);

    Vue.component(FilterGroup.name, FilterGroup);
    Vue.component(SidebarNaf.name, SidebarNaf);
    Vue.component(UserBoxNaf.name, UserBoxNaf);
    Vue.component(Calendario.name, Calendario);
    Vue.component(ModalConfirmacion.name, ModalConfirmacion);

    Vue.component(DataTableNaf.name, DataTableNaf);
    Vue.component(DataTableNafScroll.name, DataTableNafScroll);
    Vue.component(LoaderNaf.name, LoaderNaf);
  },
};
