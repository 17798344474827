<template>
  <div>
    <dropdown-search
      v-if="isAdmin()"
      v-model="farmacia.id_farmacia"
      class="dropdown-farmacia"
      :search="true"
      :label="farmaciaName || 'Farmacia'"
      style="width: 400px"
      placeholder="Buscar farmacia"
      :filter="true"
      :selectable="true"
      :multiple="false"
    >
      <dropdown-search-item
        v-for="farmacia in filteredFarmacias"
        :key="farmacia.id_farmacia"
        :text="farmacia.nombre"
        :value="farmacia.id_farmacia"
      />
    </dropdown-search>
  </div>
</template>

<script>
import userMixin from '../mixins/userValidations';
import usuarios from '@/api/usuarios.api';

export default {
  name: 'FilterFarmacias',
  extends: usuarios,
  mixins: [userMixin],
  data() {
    return {
      farmacias: [],
      userSelectedFarmacia: [],
      filteredFarmacias: [],
      searchQuery: '',
      farmacia: {
        id_farmacia: undefined,
      },
    };
  },
  computed: {
    selectedFarmaciaFromStore() {
      return this.$store.state.farmacias.selectedFarmacia;
    },
    farmaciaName() {
      const selected = this.farmacias.find(
        (farmacia) => farmacia.id_farmacia === this.farmacia.id_farmacia
      );
      return selected ? selected.nombre : null;
    },
  },
  watch: {
    'farmacia.id_farmacia': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== null) {
          if (newValue !== oldValue) {
            if (newValue !== this.selectedFarmaciaFromStore) {
              if (newValue === undefined) {
                newValue = null;
              }
              this.selectedFarmacia(newValue);
            }
          }
        }
      },
    },
  },

  mounted() {
    if (this.isAdmin()) {
      this.loadFarmacias();
      const storedFarmaciaId = this.selectedFarmaciaFromStore;
      if (storedFarmaciaId) {
        this.farmacia.id_farmacia = storedFarmaciaId;
      }
    }
  },
  methods: {
    loadFarmacias() {
      const loadPromise = new Promise((resolve) => {
        this.getFarmacias(resolve, this.request);
      });
      loadPromise
        .then((data) => {
          this.farmacias = data;
          this.filteredFarmacias = data;
        })
        .catch((error) => {
          console.error('Error al cargar las farmacias:', error);
        });
    },
    selectedFarmacia(idFarmacia) {
      const requestBody = {
        id_farmacia_seleccionada: idFarmacia,
      };

      let updatePromise = new Promise((resolve) => {
        this.selectFarmacia(resolve, requestBody);
      });
      updatePromise.then((data) => {
        this.$store.commit('farmacias/setSelectedFarmacia', idFarmacia);
        localStorage.setItem('selectedFarmaciaId', idFarmacia);
      });
      this.farmacia.id_farmacia = idFarmacia;
    },
  },
};
</script>

<style scoped>
.dropdown-farmacia {
  position: absolute !important;
  top: 20px;
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1021;
  color: var(--fc-brand-02);
}
</style>
