var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-item",class:{
    childs: _vm.hasChilds,
    'has-active-path': _vm.hasActivePath,
  },attrs:{"id":`nav-item-${
    _vm.item.url
      ? _vm.item.url.split('/').join('')
      : _vm.item.text.split(' ').join('').toLowerCase()
  }`}},[(_vm.target)?_c('a',{staticClass:"nav-link native-link",class:{
      'has-no-link': !_vm.item.url,
    },attrs:{"target":_vm.target,"href":_vm.itemUrl},on:{"click":function($event){return _vm.onClickLink(_vm.itemUrl)}}},[(_vm.item.icon && !_vm.isChild)?_c('span',{staticClass:"nav-icon",class:_vm.item.icon}):_vm._e(),_c('span',{staticClass:"nav-text"},[_vm._v(" "+_vm._s(_vm.item.text)+" ")])]):(_vm.hasChilds)?_c('a',{staticClass:"nav-link native-link",attrs:{"target":_vm.target},on:{"click":_vm.handleClickParent}},[(_vm.item.icon && !_vm.isChild)?_c('span',{staticClass:"nav-icon",class:_vm.item.icon}):_vm._e(),_c('span',{staticClass:"nav-text"},[_vm._v(" "+_vm._s(_vm.item.text)+" ")]),(_vm.hasChilds)?_c('span',{staticClass:"dropdown-chevron",class:{
        'icon-chevron-up-outline': _vm.showChilds,
        'icon-chevron-down-outline': !_vm.showChilds,
      }}):_vm._e()]):(!_vm.itemUrl.startsWith('http'))?_c('router-link',{staticClass:"nav-link router-link",class:{
      'has-no-link': !_vm.item.url,
    },attrs:{"to":_vm.normalizedUrl(_vm.itemUrl)},nativeOn:{"click":function($event){return _vm.onClickLink(_vm.itemUrl)}}},[(_vm.item.icon && !_vm.isChild)?_c('span',{staticClass:"nav-icon",class:_vm.item.icon}):(_vm.item.icon && _vm.isChild)?_c('span',{staticClass:"nav-icon is-child",class:_vm.item.icon}):_vm._e(),_c('span',{staticClass:"nav-text",class:{ 'nav-text': !_vm.isChild, 'nav-child-text': _vm.isChild }},[_vm._v(" "+_vm._s(_vm.item.text))])]):_c('a',{staticClass:"nav-link native-link",class:{
      'has-no-link': !_vm.item.url,
    },attrs:{"href":_vm.itemUrl},on:{"click":function($event){return _vm.onClickLink(_vm.itemUrl)}}},[(_vm.item.icon && !_vm.isChild)?_c('span',{staticClass:"nav-icon",class:_vm.item.icon,style:(_vm.item.linkStyle)}):_vm._e(),_c('span',{staticClass:"nav-text",style:(_vm.item.linkStyle)},[_vm._v(_vm._s(_vm.item.text))])]),(_vm.hasChilds && _vm.showChilds)?_c('div',{staticClass:"slot-div"},[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }