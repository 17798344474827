<template>
  <div class="card-item-checkbox">
    <div class="d-flex">
      <slot name="right"> </slot>
    </div>
  </div>

  <!-- <div>
     <div class="card-checkbox">
        <div class="flex-cols flex-4">
            <slot name="title">
            <h5 class="title">{{title}}</h5>
            </slot>
        </div>
       <div class="flex-cols flex-4" >
         <slot name="description">
            <check-box v-for="opcion in opciones" :key="opcion.key" v-model="opcion.selected" :label="opcion.text" @input="actualizar"/>
         </slot>
       </div>
     </div>
  
 
   </div> -->
</template>
<script>
//  import CardFormItem from "frontendcommon/src/components/design-system/CardFormItem.vue";
export default {
  name: 'CardItemCheckbox',
  //  extends:CardFormItem,

  props: {
    opciones: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkedNames: '',
    };
  },

  computed: {
    selectedNames() {
      return this.opciones
        .filter((opcion) => opcion.selected)
        .map((opcion) => opcion.text);
    },
  },
  methods: {
    actualizar: function (data) {
      this.$emit('selectedItems', this.selectedNames);
    },
  },
};
</script>
<style lang="scss">
@import 'frontendcommon/src/scss/config';
.card-item-checkbox {
  // display:flex;
  // overflow: auto;
  background: white;
  // height: 80px;
  // justify-content: center;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-color: $soft-grey;
  border-style: solid;
}
</style>
