import moment from 'moment';
export const formatTime = (hour) => {
  if (!hour) return '';
  return hour.slice(0, 5);
};

export const formatDate = (date) => {
  if (!date) return '';
  return moment(date).format('DD/MM/YYYY');
};
export const formatPrice = (price) => {
  if (!price) return '0.00';
  price = parseFloat(price);
  return price.toFixed(2) + '€';
};

const tiposTrDol = {
  DO1: 'DOL clásico',
  DO2: 'DOL a plazo',
  DO3: 'DOL creciente',
  DO4: 'DOL clásico',
  DO5: 'DOL negativo',
};

export const formatTipoTrDol = (codigo) => {
  if (tiposTrDol.hasOwnProperty(codigo)) {
    return tiposTrDol[codigo];
  }
  return codigo;
};

export const formatRetardo = (value) => {
  switch (value) {
    case 0:
      return 'Dia actual';
    case 1:
      return '24H';
    case 2:
      return '48H';
    case 3:
      return '+48H';
  }
};
