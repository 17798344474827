<template lang="">
  <BaseView :title="title" class="mb-5">
    <template #content>
      <b-container fluid>
        <b-row class="mb-3">
          <b-col>
            <ListadoDescuentos />
          </b-col>
        </b-row>
      </b-container>
    </template>
  </BaseView>
</template>

<script>
import { ListadoDescuentos } from '@/components';
import { mapGetters } from 'vuex';
export default {
  name: 'Descuentos',
  components: {
    ListadoDescuentos,
  },
  data() {
    return {
      title: 'Descuentos iniciales',
      loading: true,
    };
  },
};
</script>

<style lang=""></style>
