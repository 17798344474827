export default {
  data() {
    return {
      colors: window.colors,
    };
  },
  computed: {
    colorStyles() {
      return {
        '--fc-brand-01': this.colors.brand01,
        '--fc-brand-01-dark': this.colors.brand01Dark,
        '--fc-brand-01-medium': this.colors.brand01Medium,
        '--fc-brand-01-soft': this.colors.brand01Soft,
        '--fc-brand-02': this.colors.brand02,
        '--fc-brand-03': this.colors.brand03,
        '--fc-brand-04': this.colors.brand04,
      };
    },
    overflowStyle() {
      return {
        overflow: 'visible',
      };
    },
    combinedStyles() {
      return Object.assign({}, this.colorStyles, this.overflowStyle);
    },
  },
};
