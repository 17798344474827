<template>
  <BaseView title="Perfil usuario">
    <template slot="content">
      <div id="profile-settings">
        <div class="card-nf-settings">
          <div class="card-nf-title">
            <h5>Información básica</h5>
          </div>
          <basic-information />
        </div>

        <!-- <div class="card-nf-settings mt-5">
          <div class="card-nf-title">
            <h5>Contacto</h5>
          </div>
          <contact-information />
        </div> -->

        <!-- <div class="card-nf-settings mt-5">
          <div class="card-nf-title">
            <h5>Seguridad</h5>
          </div>
          <security-information />
        </div> -->
      </div>
    </template>
  </BaseView>
</template>

<script>
import BasicInformation from '@/components/profile/BasicInformation';
import ContactInformation from '@/components/profile/contactInfo/ContactInformation';
// import SecurityInformation from "@/components/profile/security/SecurityInformation";

export default {
  name: 'ProfileView',
  components: {
    BasicInformation,
    // SecurityInformation,
  },
};
</script>

<style lang="scss" scoped>
#profile-settings {
  .card-nf-settings {
    width: 690px;
    border: 1px solid #e2e2e2;
    background-color: #fff;
    .card-nf-title {
      padding: 25px 30px;
      * {
        font-weight: bold;
        margin: 0;
      }
    }
  }
}
</style>
