<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3 v-if="!esNuevo">{{ titleEditar }}</h3>
        <h3 v-else>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <card-form
          v-if="!loading"
          :style="combinedStyles"
          title="Informacion básica"
        >
          <template>
            <card-form-item title="Nombre">
              <template #description>
                <input-box v-model="itemMod.name" v-bind="form.name" />
              </template>
            </card-form-item>
            <card-form-item title="Email">
              <template #description>
                <input-box v-model="itemMod.email" v-bind="form.email" />
              </template>
            </card-form-item>
            <card-form-item v-if="esNuevo" title="Password">
              <template #description>
                <input-box
                  v-model="itemMod.password"
                  :label="$t('login.password')"
                  v-bind="form.password"
                  type="password"
                />
              </template>
            </card-form-item>
            <card-form-item title="Rol">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_rol"
                    label="Rol"
                    :filter="true"
                    :options="opcionesRoles"
                    v-bind="form.rol"
                  />
                </div>
              </template>
            </card-form-item>
            <card-form-item title="Farmacia">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_farmacia"
                    label="Farmacia"
                    :filter="true"
                    :options="opcionesFarmacias"
                    v-bind="form.farmacia"
                  />
                </div>
              </template>
            </card-form-item>
            <!-- <card-form-item title="Password expired">
                        <template v-slot:description>
                            <div class="d-flex justify-content-center">
                                <check-box :value="Boolean(itemMod.password_expired)" @input="itemMod.password_expired=!itemMod.password_expired" />
                            </div>
                        </template>
                    </card-form-item> -->
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import moment from 'moment';
import usuarios from '@/api/usuarios.api';
import colors from '@/mixins/colors.js';
import CheckSelector from '../../commons/forms/CheckSelector.vue';
import MultipleSelectCustom from '../../commons/forms/MultipleSelectCustom.vue';
import CheckSelectorCollapseLoad from '../../commons/forms/CheckSelectorCollapseLoad.vue';

import { mapGetters } from 'vuex';
export default {
  name: 'ModalUsuarios',
  components: {
    MultipleSelectCustom,
    CheckSelectorCollapseLoad,
  },
  extends: usuarios,
  mixins: [colors],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    esNuevo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      titleEditar: 'Editar',
      titleCrear: 'Crear',
      opcionesRoles: [],
      opcionesFarmacias: [],
      // ablitiesOptions: [],
      itemMod: {},
      form: {
        name: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        email: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        password: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        rol: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        farmacia: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      propio: false,
      esEstandar: true,
    };
  },
  watch: {
    item: {
      handler: function (req) {
        if (Object.keys(this.item).length > 0) {
          this.cargarDatosIniciales();
        }
        this.loadFiltros();

        // this.getProveedores();
      },
      immediate: true,
    },
  },
  methods: {
    cargarDatosIniciales() {
      this.itemMod = {
        ...this.item,
      };
    },
    loadFiltros: function () {
      this.loading = true;
      let rolesPromise = new Promise((resolve) => {
        this.getRoles(resolve);
      });

      let farmacaisPromise = new Promise((resolve) => {
        this.getFarmacias(resolve);
      });
      // let abilitiesPromise = new Promise((resolve) => {
      //     this.getAbilities(resolve);
      // });

      Promise.all([rolesPromise, farmacaisPromise]).then(
        ([rolesData, farmaciasData]) => {
          this.opcionesRoles = rolesData.map((item) => {
            item.text = item.descripcion;
            item.value = item.id_rol;
            return item;
          });
          this.opcionesFarmacias = farmaciasData.map((item) => {
            item.text = item.nombre;
            item.value = item.id_farmacia;
            return item;
          });
          // abilitiesData.forEach((item) =>
          //     this.ablitiesOptions.push({
          //         text: item.descripcion,
          //         value: item.id,
          //         selected: this.item.abilities ? this.item.abilities.filter(ability => ability.id == item.id).length > 0 : false,
          //         type: 'check'
          //     })
          // );
          this.loading = false;
        }
      );
    },

    // getProveedores: async function (request) {
    //     return new Promise((resolve) => {
    //         this.getProveedoresPaginated(resolve, request);
    //     }).then((data) => {
    //         let result = []
    //         data.data.forEach((item) =>
    //             result.push({
    //                 name: item.NOMBRE,
    //                 value: item.COD_LABORATORIO,
    //                 selected: false,
    //             })
    //         );
    //         return result;
    //     });
    // },
    actualizarCheck: function (data) {
      console.log(data);
    },
    guardar() {
      this.cleanForm();
      if (!this.validateForm()) {
        return;
      }
      if (this.esNuevo) {
        let createPromise = new Promise((resolve) => {
          this.createUsuarios(resolve, this.itemMod);
        });
        createPromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      } else {
        let updatePromise = new Promise((resolve) => {
          this.updateUsuarios(resolve, this.itemMod, this.itemMod.id);
        });
        updatePromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      }
    },
    validateForm() {
      let validado = true;
      // if ((this.itemMod.cod_division_lab ?? true) && (!this.itemMod.cod_laboratorio ?? true)) {
      //     this.form.division.hasError = true;
      //     this.form.division.hasValidation = true;
      //     this.form.division.errors.push({
      //         show: true,
      //         message: this.$t("error.obligatorio.laboratorio"),
      //     });
      //     validado = false
      // }

      return validado;
    },
    cleanForm: function () {
      this.form = {
        name: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        email: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        password: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        rol: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        farmacia: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
  },
};
</script>

<style lang="scss">
.form {
  overflow: visible;
}
</style>
