<template>
  <div>
    <toast
      v-if="showToast"
      class="mt-2"
      type="success-filled"
      description="Exclusión guardada correctamente"
      @timeout="handleCloseToast"
      @close="handleCloseToast"
    />

    <card>
      <template #content>
        <b-row>
          <b-col>
            <h4>{{ title }}</h4>
          </b-col>
          <b-col class="text-right">
            <b-button variant="primary" @click="crear">{{
              $t('button.create')
            }}</b-button>
          </b-col>
        </b-row>
      </template>
      <template>
        <div v-if="items.length">
          <DataTableNaf :fields="columns" :items="items" :per-page="10">
            <template #cell(descripcion)="{ item }">
              <span>{{ item.ability }}</span>
            </template>

            <template #cell(icons)="{ item }">
              <div class="d-flex justify-content-between">
                <div class="pr-2">
                  <span
                    class="icon icon-edit-outline"
                    @click="editar(item)"
                  ></span>
                </div>
                <div>
                  <span
                    class="icon icon-trash-outline"
                    @click="openConfirmacion(item)"
                  ></span>
                </div>
              </div>
            </template>
          </DataTableNaf>
        </div>
        <card-item-simple v-if="!items.length">
          <template #description>
            <div>{{ $t('sinResultados') }}</div>
          </template>
        </card-item-simple>
      </template>
    </card>
    <modal-abilities
      v-if="showModal"
      :item="texto"
      :es-nuevo="esNuevo"
      @close="close"
      @save="loadItems"
    />
    <modal-confirmacion
      v-if="showConfirmation"
      @close="close"
      @confirm="borrar"
    />
  </div>
</template>

<script>
import usuarios from '@/api/usuarios.api';
import colors from '@/mixins/colors.js';
import ModalAbilities from './ModalAbilities.vue';
export default {
  name: 'ListadoAbilities',
  components: {
    ModalAbilities,
  },
  extends: usuarios,
  mixins: [colors],
  data() {
    return {
      title: 'Abilities',
      showModal: false,
      showConfirmation: false,
      items: [],
      esNuevo: false,
      texto: {},
      showToast: false,
      columns: [
        {
          key: 'descripcion',
          sortable: true,
          label: 'Descipcion',
        },
        {
          key: 'icons',
          sortable: false,
          label: '',
          class: 'justify-content-end',
        },
      ],
      request: {},
      loading: true,
      itemToDelete: null,
    };
  },
  watch: {
    request: function () {
      this.loadItems();
    },
    reload: function () {
      this.loadItems();
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    loadItems: function () {
      let loadPromise = new Promise((resolve) => {
        this.getAbilities(resolve, this.request);
      });
      loadPromise.then((data) => {
        this.items = [];
        this.items = data;
      });
    },
    editar: function (item) {
      this.esNuevo = false;
      this.texto = {
        ...item,
      };
      this.showModal = true;
    },
    crear: function () {
      this.esNuevo = true;
      this.texto = {};
      this.showModal = true;
    },
    borrar: function (item) {
      this.showConfirmation = false;
      if (this.itemToDelete) {
        let deletePromise = new Promise((resolve) => {
          this.deleteAbilities(resolve, item, this.itemToDelete);
        });
        deletePromise.then(() => {
          this.loadItems();
        });
      }
    },
    close: function () {
      this.showConfirmation = false;
      this.showModal = false;
    },
    openConfirmacion: function (item) {
      this.itemToDelete = item.id_ability;
      console.log(this.itemToDelete);
      this.showConfirmation = true;
    },
    handleCloseToast() {
      this.showToast = false;
    },
  },
};
</script>

<style lang="scss" scope></style>
