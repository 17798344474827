<template>
  <modal size="sm" padding-size="sm" @hide="$emit('close')">
    <template>
      <div id="modal-change-password">
        <h3>Seguridad</h3>
        <p>Modifica tu contraseña de acceso</p>

        <div class="mt-5 mb-5">
          <input-box
            v-model="credentials.old_password"
            label="Contraseña antigua"
            :has-error="form.old_password.hasError"
            type="password"
            class="mb-2"
            :errors="form.old_password.errors"
          />

          <input-box
            v-model="credentials.new_password"
            label="Nueva contraseña"
            :has-error="form.new_password.hasError"
            type="password"
            class="mb-2"
            :errors="form.new_password.errors"
          />

          <input-box
            v-model="credentials.confirm_password"
            label="Confirmar nueva contraseña"
            :has-error="form.confirm_password.hasError"
            type="password"
            class="mb-2"
            :errors="form.confirm_password.errors"
          />
        </div>

        <div class="d-flex">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('generals.cancel')
          }}</b-button>
          <b-button variant="primary" class="ml-3" @click="initResetPassword">{{
            $t('generals.apply')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
// import profile from "@/api/profile";

export default {
  name: 'ModalChangePassword',
  // extends: profile,
  data() {
    return {
      showResetPassword: true,
      credentials: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
      form: {
        old_password: {
          hasError: false,
          errors: [],
        },
        new_password: {
          hasError: false,
          errors: [],
        },
        confirm_password: {
          hasError: false,
          errors: [],
        },
      },
    };
  },
  methods: {
    initResetPassword: function () {
      this.cleanForm();
      if (
        this.credentials.old_password === '' ||
        this.credentials.new_password === '' ||
        this.credentials.confirm_password === ''
      ) {
        this.form.confirm_password.hasError = true;
        this.form.confirm_password.errors.push({
          show: true,
          message: 'Todos los campos de contraseñas son obligatorios.',
        });
      } else if (
        this.credentials.new_password !== this.credentials.confirm_password
      ) {
        this.form.confirm_password.hasError = true;
        this.form.confirm_password.errors.push({
          show: true,
          message: 'Las contraseñas no coindicen. Por favor, revise los datos.',
        });
      } else this.changePassword();
    },
    cleanForm: function () {
      this.form = {
        old_password: {
          hasError: false,
          errors: [],
        },
        new_password: {
          hasError: false,
          errors: [],
        },
        confirm_password: {
          hasError: false,
          errors: [],
        },
      };
    },
    changePassword: function () {
      let changePasswordPromise = new Promise((resolve) => {
        this.changePasswordApi(
          resolve,
          this.credentials.old_password,
          this.credentials.new_password,
          this.credentials.confirm_password
        );
      });
      changePasswordPromise.then((data) => {
        if (data.length > 0) {
          this.form.confirm_password.hasError = true;
          this.form.confirm_password.errors.push({
            show: true,
            message: data[0],
          });
        } else {
          this.$emit('close');
        }
      });
    },
  },
};
</script>

<style lang="scss">
#modal-change-password {
  .form-error-box.min-height {
    min-height: 0;
  }
}
</style>
