<template>
  <sidebar-naf
    :nav-items="items"
    :logo-src="require(`./assets/logo.png`)"
    :logo-mini-src="require(`./assets/logo.png`)"
  />
</template>

<script>
import menu from '@/api/menu.api';

import { mapGetters } from 'vuex';
export default {
  name: 'SidebarDashboard',
  extends: menu,

  data() {
    return {
      items: [],
    };
  },
  created() {
    //
    this.loadItems();
  },
  methods: {
    ...mapGetters(['getUser']),
    loadItems: function () {
      let loadPromise = new Promise((resolve) => {
        this.getMenu(resolve, {});
      });
      loadPromise.then((data) => {
        this.menu = [];
        this.createMenu(data);
      });
    },
    createMenu: function (data) {
      data.forEach((menu) => {
        if (menu.visible_sidebar === 1 && menu.id_menu_padre === null) {
          let menuObject = {
            item: {
              icon: menu.icono,
              text: menu.nombre,
              url: menu.path,
            },
          };
          let submenuArray = data.filter(function (submenu) {
            return (
              submenu.id_menu_padre === menu.id_menu &&
              submenu.visible_sidebar === 1
            );
          });

          if (submenuArray.length > 0) {
            menuObject.childs = submenuArray.map((item) => ({
              icon: item.icono,
              text: item?.nombre,
              url: item.path,
            }));
          }
          this.items.push(menuObject);
        }
      });
    },
  },
};
</script>
