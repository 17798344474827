import store from '../store';
import { saveAs } from 'file-saver';

const accessToken = store.getters.getAccessToken;
import httpClient from './httpClient';

const END_POINT = '/';
export default {
  methods: {
    //GESTIÓN MENU
    getMenu: function (resolve, request) {
      httpClient
        .get(END_POINT + 'menu/index', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // getMenu:function (resolve,request) {
    //     httpClient.post(END_POINT+'menu/find',request)
    //     .then((response) => {
    //         resolve(response.data);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // },
    // getAllMenu:function (resolve,request) {
    //     httpClient.post(END_POINT+'menu/all',request)
    //     .then((response) => {
    //         resolve(response.data);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // },
    // updateMenu:function (resolve,request) {
    //     httpClient.post(END_POINT+'menu/update',request)
    //     .then((response) => {
    //         resolve(response.data);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // },

    // deleteMenu:function (resolve,request) {
    //     httpClient.post(END_POINT+'menu/delete',request)
    //     .then((response) => {
    //         resolve(response.data);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // },

    //GESTIÓN PERMISOS
    getPermiso: function (resolve, request) {
      httpClient
        .post(END_POINT + 'permiso/find', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updatePermiso: function (resolve, request) {
      httpClient
        .post(END_POINT + 'permiso/update', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(Promise.reject(error.response));
        });
    },

    deletePermiso: function (resolve, request) {
      httpClient
        .post(END_POINT + 'permiso/delete', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUsuarios: function (resolve, request) {
      httpClient
        .post(END_POINT + 'usuario/find', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRoles: function (resolve, request) {
      httpClient
        .post(END_POINT + 'rol/find', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
