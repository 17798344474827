import store from '../store';

const accessToken = store.getters.getAccessToken;
import httpClient from './httpClient';

const PATH = '/laboratorio/';

export default {
  methods: {
    getLaboratorios: function (resolve, request) {
      httpClient
        .get(PATH + 'index', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
