import usuariosApi from '../../api/usuarios.api';

const state = {
  tokenSellout: null,
  loading: false,
  error: null,
};

const mutations = {
  setTokenSellout(state, token) {
    state.tokenSellout = token;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

const actions = {
  async loadTokenSellout({ commit }, request) {
    commit('setLoading', true);

    try {
      const data = await new Promise((resolve, reject) => {
        usuariosApi.methods.getTokenSellout((data) => {
          if (data) {
            resolve(data);
          } else {
            reject('No se pudo obtener el token sellout');
          }
        }, request);
      });

      commit('setTokenSellout', data);
      return data;
    } catch (error) {
      commit('setError', error);
      throw new Error(error);
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
