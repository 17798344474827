<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <toast
          v-if="showToastError"
          class="mt-2"
          type="error-filled"
          :description="textoError"
          @timeout="handleCloseToastError"
          @close="handleCloseToastError"
        />
        <card-form
          v-if="!loading"
          :style="combinedStyles"
          title="Informacion básica"
        >
          <template>
            <card-form-item title="Menu">
              <template #description>
                <select-box
                  v-model="itemMod.menu_id"
                  label="Menu"
                  :filter="true"
                  :options="opcionesMenus"
                  v-bind="form.menu"
                />
              </template>
            </card-form-item>
            <card-form-item v-if="!itemMod.rol_id" title="Usuario">
              <template #description>
                <select-box
                  v-model="itemMod.user_id"
                  label="Usuario"
                  :filter="true"
                  :options="opcionesUsuarios"
                  v-bind="form.user"
                  :clear-icon="true"
                  @clear="itemMod.user_id = null"
                />
              </template>
            </card-form-item>
            <card-form-item v-if="!itemMod.user_id" title="Rol">
              <template #description>
                <select-box
                  v-model="itemMod.rol_id"
                  label="Rol"
                  :filter="true"
                  :options="opcionesRoles"
                  v-bind="form.rol"
                  :clear-icon="true"
                  @clear="itemMod.rol_id = null"
                />
              </template>
            </card-form-item>
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import menu from '@/api/menu.api';
import colors from '@/mixins/colors.js';

export default {
  name: 'ModalPermisos',
  extends: menu,
  mixins: [colors],
  data() {
    return {
      loading: false,
      titleCrear: 'Crear',
      opcionesRoles: [],
      opcionesMenus: [],
      opcionesUsuarios: [],
      showToastError: false,
      textoError: '',
      itemMod: {},
      form: {
        menu: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        user: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        rol: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      esEstandar: true,
    };
  },
  created() {
    this.loadFiltros();
  },
  methods: {
    loadFiltros: function () {
      this.loading = true;
      let menuPromise = new Promise((resolve) => {
        this.getAllMenu(resolve);
      });
      let rolesPromise = new Promise((resolve) => {
        this.getRoles(resolve);
      });
      let usuarioPromise = new Promise((resolve) => {
        this.getUsuarios(resolve);
      });

      Promise.all([menuPromise, rolesPromise, usuarioPromise]).then(
        ([menuData, rolesData, usuariosData]) => {
          this.opcionesMenus = menuData.map((item) => {
            item.text = item.nombre;
            item.value = item.id;
            return item;
          });
          this.opcionesRoles = rolesData.map((item) => {
            item.text = item.descripcion;
            item.value = item.id;
            return item;
          });
          this.opcionesUsuarios = usuariosData.map((item) => {
            item.text = item.username;
            item.value = item.id;
            return item;
          });
          this.loading = false;
        }
      );
    },

    actualizarCheck: function (data) {
      console.log(data);
    },
    guardar() {
      this.cleanForm();
      if (!this.validateForm()) {
        return;
      }
      let itemPromise = new Promise((resolve) => {
        this.updatePermiso(resolve, this.itemMod);
      });
      itemPromise
        .then(() => {
          this.$emit('save');
          this.$emit('close');
        })
        .catch((error) => {
          console.log(error);
          this.textoError = error.data.message;
          this.showToastError = true;
        });
    },
    validateForm() {
      let validado = true;
      if (!this.itemMod.menu_id ?? true) {
        this.form.menu.hasError = true;
        this.form.menu.hasValidation = true;
        this.form.menu.errors.push({
          show: true,
          message: this.$t('error.obligatorio.default'),
        });
        validado = false;
      }
      if (!this.itemMod.user_id && !this.itemMod.rol_id) {
        console.log('ENTRO');
        this.form.user.hasError = true;
        this.form.user.hasValidation = true;
        this.form.user.errors.push({
          show: true,
          message: this.$t('error.obligatorio.rolUsuario'),
        });

        this.form.rol.hasError = true;
        this.form.rol.hasValidation = true;
        this.form.rol.errors.push({
          show: true,
          message: this.$t('error.obligatorio.rolUsuario'),
        });

        validado = false;
      } else if (this.itemMod.user_id && this.itemMod.rol_id) {
        this.form.user.hasError = true;
        this.form.user.hasValidation = true;
        this.form.user.errors.push({
          show: true,
          message: this.$t('error.rolUsuarioUnico'),
        });

        this.form.rol.hasError = true;
        this.form.rol.hasValidation = true;
        this.form.rol.errors.push({
          show: true,
          message: this.$t('error.rolUsuarioUnico'),
        });

        validado = false;
      }

      return validado;
    },
    cleanForm: function () {
      this.form = {
        menu: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        user: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        rol: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
    handleCloseToastError() {
      this.showToastError = false;
    },
  },
};
</script>

<style lang="scss">
.form {
  overflow: visible;
}
</style>
