<template lang="">
  <div class="card-row">
    <div class="flex-cols flex-4">
      <slot name="description">
        <span class="description">{{ description }}</span>
      </slot>
    </div>
    <div class="flex-cols">
      <slot name="icon">
        <span class="icon" :class="icon" @click="$emit('click-icon')"></span>
      </slot>
    </div>
  </div>
</template>
<script>
import CardFormItem from 'frontendcommon/src/components/design-system/CardFormItem.vue';
export default {
  name: 'CardItemSimple',
  extends: CardFormItem,
};
</script>
<style lang="scss"></style>
