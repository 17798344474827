const KEY_STORE = 'store-proveedoresunnefar';

const basic = {
  state: {
    user: null,
    access_token: null,
    username: null,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getUsername(state) {
      return state.username;
    },
    getAccessToken(state) {
      return state.access_token;
    },
    isAuthenticated(state) {
      //Usamos el local storage porque el state devuelve null en router
      if (localStorage.getItem(KEY_STORE)) {
        let stateStore = JSON.parse(localStorage.getItem(KEY_STORE));
        return stateStore != null && stateStore.access_token != null;
      }
      return false;
    },
  },
  mutations: {
    initialiseStore: (state) => {
      if (localStorage.getItem(KEY_STORE)) {
        let stateStore = JSON.parse(localStorage.getItem(KEY_STORE));
        state.user = stateStore.user;
        state.access_token = stateStore.access_token;
        state.username = stateStore.username;
      }
    },
    saveStore: (state) => {
      localStorage.setItem(KEY_STORE, JSON.stringify(state));
    },
    removeStore: (state) => {
      localStorage.removeItem(KEY_STORE);
    },
    updateUser(state, value) {
      state.user = value;
    },
    updateUsername(state, value) {
      state.username = value;
    },
    updateAccessToken(state, value) {
      state.access_token = value;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
      commit('saveStore');
    },
    saveUser({ commit }, value) {
      commit('updateUser', value);
      // commit('updateCoop',value.cooperativa)
      commit('saveStore');
    },
    saveUsername({ commit }, value) {
      commit('updateUsername', value);
      commit('saveStore');
    },
    saveAccessToken({ commit }, value) {
      commit('updateAccessToken', value);
      commit('saveStore');
    },
    initialiseStoreAndUser({ commit }) {
      commit('initialiseStore');
      commit('saveStore');
    },
    destroyStore({ commit }) {
      commit('removeStore');
      commit('initialiseStore');
    },
  },
};

export default basic;
