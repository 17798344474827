<template>
  <div :style="colorStyles">
    <card :paddless="true">
      <template #header>
        <div class="m-4">
          <div>
            <div class="d-flex">
              <h3 class="pr-4">
                <strong>{{ transfer.TIPO | formatTipoTrDol }}</strong>
              </h3>
              <h3>
                <strong>{{ transfer.IDTRANSFERLAB }}</strong>
              </h3>
            </div>
          </div>
          <div class="d-inline-flex">
            <div class="mr-3">
              <h6>
                Fecha inicio de compra:
                <span v-if="transfer.FECHAINICOMPRO">{{
                  transfer.FECHAINICOMPRO | formatDate
                }}</span
                ><span v-else>-</span>
              </h6>
            </div>
            <div class="mr-3">
              <h6>
                Fecha fin de compra:
                <span v-if="transfer.FECHAFINCOMPRO">{{
                  transfer.FECHAFINCOMPRO | formatDate
                }}</span
                ><span v-else>-</span>
              </h6>
            </div>
            <div class="mr-3">
              <h6>
                Fecha recepción:
                <span v-if="transfer.FECHARECEPCION">{{
                  transfer.FECHARECEPCION | formatDate
                }}</span
                ><span v-else>-</span>
              </h6>
            </div>
            <div class="mr-3">
              <h6>
                Fecha primer movimiento:
                <span v-if="transfer.FECHAINICOMPRO">{{
                  transfer.FECHAINICOMPRO | formatDate
                }}</span
                ><span v-else>-</span>
              </h6>
            </div>
          </div>
        </div>
      </template>
      <template>
        <div :style="colorStyles">
          <DataTableNaf
            :fields="columns"
            :items="items"
            :stand-alone="false"
            grid-cols="3fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr"
          >
            <template #cell(cliente)="{ item }">
              <div>
                <span v-if="item.farmacia_cooperativa" class="mb-2 pt-2">{{
                  item.farmacia_cooperativa.farmacia_unnefar.NOMBRE
                }}</span>
                <span v-else>-</span>
              </div>
            </template>
            <template #cell(nif)="{ item }">
              <span v-if="item.farmacia_cooperativa">{{
                item.farmacia_cooperativa.farmacia_unnefar.NIF
              }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(cooperativa)="{ item }">
              <span v-if="item.cooperativa">{{
                item.cooperativa.ABREVIATURA
              }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(provincia)="{ item }">
              <span v-if="item.farmacia_cooperativa">{{
                item.farmacia_cooperativa.provincia.DESCRIPCION
              }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(udContratadas)="{ item }">
              <span>{{ item.UD_SOLICITADAS }}</span>
            </template>
            <template #cell(udServidas)="{ item }">
              <span>{{ item.UD_SERVIDAS }}</span>
            </template>
            <template #cell(grupal)="{ item }">
              <span>{{ item.GRUPAL }}</span>
            </template>
            <template #cell(codgrupocop)="{ item }">
              <span v-if="item.CODGRUPOCOP">{{ item.CODGRUPOCOP }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(importeBruto)="{ item }">
              <span>{{ item.IMPORTE_BRUTO | formatPrice }}</span>
            </template>
            <template #cell(importeNeto)="{ item }">
              <span>{{ item.IMPORTE_NETO | formatPrice }}</span>
            </template>
            <template #cell(situacion)="{ item }">
              <span
                :style="{
                  color:
                    item.SITUACION === 'SERVIDO'
                      ? colors.success
                      : item.SITUACION === 'EN CURSO'
                        ? colors.warning
                        : colors.danger,
                }"
              >
                {{ item.SITUACION }}
              </span>
            </template>
          </DataTableNaf>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import colors from '@/mixins/colors.js';
import { formatPrice, formatDate, formatTipoTrDol } from '@/filters/formats';
export default {
  name: 'CardCabecera',
  filters: {
    formatPrice,
    formatDate,
    formatTipoTrDol,
  },
  mixins: [colors],
  props: ['transfer'],
  data() {
    return {
      colors: window.colors,
      columns: [
        {
          key: 'cliente',
          label: 'Cliente',
          sortable: false,
        },
        {
          key: 'nif',
          label: 'NIF',
          sortable: false,
        },
        {
          key: 'cooperativa',
          label: 'Cooperativa',
          sortable: false,
        },
        {
          key: 'provincia',
          label: 'Provincia',
          sortable: false,
        },
        {
          key: 'udContratadas',
          label: 'Unidades contratadas',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'udServidas',
          label: 'Unidades servidas',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'grupal',
          label: 'Grupal',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'codgrupocop',
          label: 'Código grupo',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'importeBruto',
          label: 'Importe bruto',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'importeNeto',
          label: 'Importe neto',
          sortable: false,
          class: 'justify-content-center',
        },
        {
          key: 'situacion',
          label: 'Situación',
          sortable: false,
        },
      ],
      items: [],
    };
  },
  created() {
    this.items.push(this.transfer);
  },
};
</script>

<style lang="scss" scoped></style>
