import store from '../store';
import { saveAs } from 'file-saver';
// // const accessToken = store.getters.getAccessToken;
import httpClient from './httpClient';

const END_POINT = '/';
const USUARIO = 'user/';
export default {
  methods: {
    //GESTIÓN USUARIOS
    getUsuarios: function (resolve, request) {
      httpClient
        .get(END_POINT + USUARIO + 'index', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUsuarioById: function (resolve, request, id) {
      httpClient
        .get(END_POINT + USUARIO + `show/${id}`, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createUsuarios: function (resolve, request) {
      httpClient
        .post(END_POINT + USUARIO + `create`, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateUsuarios: function (resolve, request, id) {
      httpClient
        .post(END_POINT + USUARIO + `update/${id}`, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteUsuarios: function (resolve, request, id) {
      httpClient
        .delete(END_POINT + USUARIO + `destroy/${id}`, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //GESTIÓN ROLES
    getRoles: function (resolve, request) {
      httpClient
        .get(END_POINT + 'rol/index', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createRoles: function (resolve, request) {
      httpClient
        .post(END_POINT + 'rol/create', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateRoles: function (resolve, request, id) {
      httpClient
        .post(END_POINT + `rol/update/${id}`, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteRoles: function (resolve, request, id) {
      httpClient
        .delete(END_POINT + `rol/destroy/${id}`, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //GESTIÓN ABILITIES
    getAbilities: function (resolve, request) {
      httpClient
        .get(END_POINT + 'ability/index', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createAbilities: function (resolve, request) {
      httpClient
        .post(END_POINT + `ability/create`, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateAbilities: function (resolve, request, id) {
      httpClient
        .post(END_POINT + `ability/update/${id}`, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteAbilities: function (resolve, request, id) {
      httpClient
        .delete(END_POINT + `ability/destroy/${id}`, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //MAESTROS
    getFarmacias: function (resolve, request) {
      httpClient
        .get(END_POINT + 'farmacia/index', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectFarmacia: function (resolve, request) {
      httpClient
        .post(END_POINT + USUARIO + 'seleccionarfarmacia', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTokenSellout: function (resolve, request) {
      httpClient
        .get(END_POINT + USUARIO + 'gettokensellout', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // getCooperativas: function (resolve) {
    //     httpClient.get(END_POINT+ 'maestro/getCooperativas')
    //     .then((response) => {
    //         resolve(response.data);
    //     })
    //     .catch((error) => {

    //     });
    // },
    // getProveedoresPaginated:function (resolve,request) {
    //     httpClient.post(END_POINT+ 'maestro/getProveedores',request)
    //     .then((response) => {
    //         resolve(response.data);
    //     })
    //     .catch((error) => {
    //         // console.log(error);
    //     });
    // },
    // getProvincias: function (resolve) {
    //     httpClient.get(END_POINT+ 'maestro/getProvincias')
    //     .then((response) => {
    //         resolve(response.data);
    //     })
    //     .catch((error) => {

    //     });
    // },
    // getDivisionesLaboratorios: function (resolve) {
    //     httpClient.get(END_POINT+ 'maestro/getDivisionesLaboratorios')
    //     .then((response) => {
    //         resolve(response.data);
    //     })
    //     .catch((error) => {

    //     });
    // },
  },
};
