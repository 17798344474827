import store from '../store';

import httpClient from './httpClient';
import { mapActions, mapGetters } from 'vuex';

const END_POINT = '/';

export default {
  methods: {
    ...mapActions(['saveAccessToken']),
    ...mapGetters(['getAccessToken']),
    login: function (resolve, credentials) {
      httpClient
        .post(END_POINT + 'login', {
          username: credentials.username,
          password: credentials.password,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          resolve(false);
        });
    },
    // resetPasswordApi: function (resolve, email) {
    //     httpClient.post(END_POINT + 'resetpassword', {
    //         email: email
    //     })
    //     .then((response) => {
    //         resolve(response.data);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         resolve(false);
    //     });
    // },
    sessionStatus: function (resolve) {
      httpClient
        .get(END_POINT + 'sessionStatus')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
