<template>
  <v-calendar
    ref="customCalendar"
    :locale="{ id: 'es-ES', firstDayOfWeek: 2, masks: { weekdays: 'WWW' } }"
    day-names-short
    class="custom-calendar max-w-full"
    :class="{ 'hide-arrows': !navVisibility }"
    :attributes="eventos"
    is-expanded
    trim-weeks
    :from-page="page"
    @update:from-page="onUpdateMonth"
  >
    <template #header-title="page">
      <span class="header-title">{{ page.monthLabel }}</span>
    </template>

    <template #header-left-button>
      <span class="icon-arrow-circle-left-outline header-button"></span>
    </template>

    <template #header-right-button>
      <span class="icon-arrow-circle-right-outline header-button"></span>
    </template>

    <template #day-content="{ day, eventos }">
      <slot name="day" :day="day" :day-events="eventos"></slot>
    </template>
  </v-calendar>
</template>
<script>
import Calendar from 'frontendcommon/src/components/advanced/Calendar.vue';

export default {
  name: 'Calendario',
  extends: Calendar,
  props: {
    eventos: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import 'frontendcommon/src/scss/config';
.custom-calendar {
  border-width: 0 !important;
}
::v-deep .vc-header {
  margin-bottom: 30px;
}
.header-button {
  font-size: 20px;
  color: $dark-grey;
}
.header-title {
  @include button();
  color: $dark-grey;
}
::v-deep .vc-weekday {
  @include h8();
  color: $medium-grey;
}
::v-deep .vc-day {
  position: relative;
  border: 1px solid $light-grey;
  padding-top: 90%;
}
::v-deep .is-today .day-header span {
  background: $brand-03;
  color: white;
}

.hide-arrows ::v-deep .vc-arrow {
  display: none;
}
::v-deep .vc-grid-container {
  overflow: visible;
}
</style>
