<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3 v-if="!esNuevo">{{ titleEditar }}</h3>
        <h3 v-else>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <card-form
          v-if="!loading"
          :style="combinedStyles"
          title="Informacion básica"
        >
          <template>
            <card-form-item title="Nombre">
              <template #description>
                <input-box v-model="itemMod.nombre" v-bind="form.nombre" />
              </template>
            </card-form-item>
            <card-form-item title="Icono">
              <template #description>
                <input-box v-model="itemMod.icono" v-bind="form.icono" />
              </template>
            </card-form-item>
            <card-form-item title="Ruta">
              <template #description>
                <input-box v-model="itemMod.ruta" v-bind="form.ruta" />
              </template>
            </card-form-item>
            <card-form-item title="Orden">
              <template #description>
                <input-box v-model="itemMod.orden" v-bind="form.orden" />
              </template>
            </card-form-item>
            <card-form-item title="Activo">
              <template #description>
                <div class="d-flex justify-content-center">
                  <check-box
                    :value="Boolean(itemMod.visible)"
                    @input="itemMod.visible = !itemMod.visible"
                  />
                </div>
              </template>
            </card-form-item>
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import menu from '@/api/menu.api';
import colors from '@/mixins/colors.js';
import { mapGetters } from 'vuex';
export default {
  name: 'ModalMenu',
  extends: menu,
  mixins: [colors],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    esNuevo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      titleEditar: 'Editar',
      titleCrear: 'Crear',
      opcionesRoles: [],
      opcionesLaboratorio: [],
      opcionesCooperativa: [],
      ablitiesOptions: [],
      provinciasOptions: [],
      itemMod: {},
      form: {
        nombre: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        icono: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        ruta: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        orden: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        visible: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      esEstandar: true,
    };
  },
  watch: {
    item: {
      handler: function () {
        if (Object.keys(this.item).length > 0) {
          this.cargarDatosIniciales();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapGetters(['isCoop', 'isLab']),
    cargarDatosIniciales() {
      this.itemMod = {
        ...this.item,
        cooperativa: this.item.cooperativa
          ? this.item.cooperativa.CODIGO
          : null,
        laboratorio: this.item.laboratorio
          ? this.item.laboratorio.COD_LABORATORIO
          : null,
      };
      if (this.item.laboratorio) {
        this.opcionesLaboratorio.push({
          name: this.item.laboratorio.NOMBRE,
          value: this.item.laboratorio.COD_LABORATORIO,
          selected: true,
        });
      }
    },

    actualizarCheck: function (data) {
      console.log(data);
    },
    guardar() {
      this.cleanForm();
      if (!this.validateForm()) {
        return;
      }
      let itemPromise = new Promise((resolve) => {
        this.updateMenu(resolve, this.itemMod);
      });
      itemPromise.then(() => {
        this.$emit('save');
        this.$emit('close');
      });
    },
    validateForm() {
      let validado = true;
      // if (!this.itemMod.id_cooperativa ?? true) {
      //     this.form.cooperativa.hasError = true;
      //     this.form.cooperativa.hasValidation = true;
      //     this.form.cooperativa.errors.push({
      //         show: true,
      //         message: this.$t("error.obligatorio.default"),
      //     });
      //     validado = false
      // }

      return validado;
    },
    cleanForm: function () {
      this.form = {
        nombre: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        icono: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        ruta: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        orden: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        visible: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
  },
};
</script>

<style lang="scss">
.form {
  overflow: visible;
}
</style>
