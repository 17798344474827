<template lang="">
  <BaseView :title="title" class="mb-5">
    <template #content>
      <b-container fluid>
        <b-row class="mb-3">
          <b-col>
            <listado-menu />
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <listado-permisos />
          </b-col>
        </b-row>
      </b-container>
    </template>
  </BaseView>
</template>

<script>
import { ListadoMenu, ListadoPermisos } from '@/components';
export default {
  name: 'Menu',
  components: {
    ListadoMenu,
    ListadoPermisos,
  },
  data() {
    return {
      title: 'Menu',
      loading: true,
    };
  },
};
</script>

<style lang=""></style>
