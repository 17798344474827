<template>
  <div id="dashboard">
    <Home />
    <SidebarDashboard />
    <div class="right-part">
      <div :class="{ 'right-part-container': currentRouteName }">
        <router-view> </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from '@/components/sidebar/SidebarDashboard';
import Home from '../views/dashboard/Home.vue';

export default {
  name: 'Dashboard',
  components: {
    SidebarDashboard,
    Home,
  },
  computed: {
    currentRouteName() {
      return !this.$route.path.includes('buscador');
    },
  },
};
</script>

<style lang="scss">
#dashboard {
  margin-left: 260px;
  width: calc(100% - 260px);
  height: 100vh;
  overflow-y: auto;
  .right-part-container {
    // width: 100%;
    // max-width: 1350px;
    // margin: 0 0rem;
    padding: 0 0px;
  }
}

@media screen and (max-width: 1300px) {
  #dashboard {
    margin-left: 80px;
    width: calc(100% - 80px);
  }
}

@media screen and (max-width: 992px) {
  #dashboard {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
}
</style>
