<template>
  <div class="filter-container">
    <farmacia-selector />
    <tipo-selector />
    <laboratorios-selector />
    <categorias-selector />
    <producto-selector />
  </div>
</template>

<script>
import FarmaciaSelector from './selectoresFiltros/FarmaciaSelector.vue';
import LaboratoriosSelector from './selectoresFiltros/LaboratoriosSelector.vue';
import TipoSelector from './selectoresFiltros/TipoSelector.vue';
import CategoriasSelector from './selectoresFiltros/CategoriaSelector.vue';
import ProductoSelector from './selectoresFiltros/ProductoSelector.vue';

export default {
  name: 'FiltroGeneral',
  components: {
    FarmaciaSelector,
    LaboratoriosSelector,
    TipoSelector,
    CategoriasSelector,
    ProductoSelector,
  },
  methods: {},
};
</script>

<style scoped>
.filter-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 2rem;
}
</style>
