<template>
  <div class="check-selector">
    <div class="check-selector-label">{{ label }}</div>
    <div class="check-selector-content">
      <div class="check-selector-label-container" @click="collapse = !collapse">
        <p>{{ getFilterNameElementsSelected }}</p>
        <span
          class="icon-chevron-down-outline"
          :class="{ open: collapse }"
        ></span>
      </div>
      <div v-if="collapse" class="check-selector-content-options">
        <multiple-select-inner-check
          :suboptions="options"
          @checked="checked(index, ...arguments)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MultipleSelectInnerCheck from './multipleSelectInner/MultipleSelectInnerCheck';

export default {
  name: 'CheckSelector',
  components: {
    MultipleSelectInnerCheck,
  },
  props: ['label', 'options'],
  data() {
    return {
      collapse: false,
    };
  },
  computed: {
    getNumberElementsSelected: function () {
      let number = 0;
      if (this.options) {
        this.options.forEach((item) => {
          if (item.selected) number++;
        });
      }

      return number;
    },
    getFilterNameElementsSelected: function () {
      let text = '';
      if (this.options) {
        this.options.forEach((item) => {
          if (item.selected) text += item.text + ' / ';
        });
      }
      return text.slice(0, -2);
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    checked: function (index, value) {
      this.$emit('checked', { item: value, index: index });
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.collapse = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-selector {
  display: flex;
  align-items: center;
  .check-selector-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .check-selector-label {
    font-size: 13px;
    line-height: 20px;
  }
  .check-selector-content {
    position: relative;
    display: flex;
    align-items: center;
    p {
      margin: 0 0 0 15px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: var(--fc-brand-01);
    }
    .icon-chevron-down-outline {
      cursor: pointer;
      color: var(--fc-brand-01);
      font-size: 22px;
      line-height: 22px;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      margin-left: 10px;
      &.open {
        transform: rotate(180deg);
      }
    }
    .check-selector-content-options {
      z-index: 999;
      position: absolute;
      top: 45px;
      left: -150px;
      background-color: #fff;
      width: 300px;
      box-shadow: 0px 2px 6px rgba(226, 226, 226, 0.5);
      border-radius: 6px;
      padding: 15px;
    }
  }
}
</style>
