const KEY_FARMACIA_STORE = 'selectedFarmacia';

const farmacias = {
  namespaced: true,
  state: {
    selectedFarmacia: localStorage.getItem(KEY_FARMACIA_STORE)
      ? parseInt(localStorage.getItem(KEY_FARMACIA_STORE), 10)
      : null,
  },
  getters: {
    getSelectedFarmacia(state) {
      return state.selectedFarmacia;
    },
  },
  mutations: {
    setSelectedFarmacia(state, value) {
      state.selectedFarmacia = value;
      localStorage.setItem(KEY_FARMACIA_STORE, value);
    },
  },
  actions: {
    setFarmacia({ commit }, value) {
      commit('setSelectedFarmacia', value);
    },
  },
};

export default farmacias;
