<template>
  <modal size="sm" padding-size="sm" @hide="$emit('close')">
    <template>
      <div id="modal-change-password" :style="combinedStyles">
        <h3>Seguridad</h3>
        <p>Modifica la contraseña de {{ user.username }}</p>

        <div class="mt-5 mb-5">
          <input-box
            v-model="user.password"
            label="Nueva contraseña"
            :has-error="form.password.hasError"
            type="password"
            class="mb-2"
            :errors="form.password.errors"
          />

          <input-box
            v-model="user.confirm_password"
            label="Confirmar nueva contraseña"
            :has-error="form.confirm_password.hasError"
            type="password"
            class="mb-2"
            :errors="form.confirm_password.errors"
          />
        </div>

        <div class="d-flex">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
          <b-button variant="primary" class="ml-3" @click="initResetPassword">{{
            $t('button.apply')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import usuarios from '@/api/usuarios.api';
import colors from '@/mixins/colors.js';
export default {
  name: 'ModalResetPassword',
  extends: usuarios,
  mixins: [colors],
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showResetPassword: true,
      credentials: {
        password: '',
        confirm_password: '',
      },
      form: {
        password: {
          hasError: false,
          errors: [],
        },
        confirm_password: {
          hasError: false,
          errors: [],
        },
      },
    };
  },
  methods: {
    initResetPassword: function () {
      this.cleanForm();
      if (!this.validateForm()) {
        console.log('ERROR');
        return;
      }
      this.changePassword();
    },
    validateForm() {
      let validado = true;
      if (this.user.password === '') {
        this.form.password.hasError = true;
        this.form.password.errors.push({
          show: true,
          message: this.$t('error.obligatorio.default'),
        });
        validado = false;
      }
      if (this.user.confirm_password === '') {
        this.form.confirm_password.hasError = true;
        this.form.confirm_password.errors.push({
          show: true,
          message: this.$t('error.obligatorio.default'),
        });
        validado = false;
      }
      if (this.user.password !== this.user.confirm_password) {
        this.form.confirm_password.hasError = true;
        this.form.confirm_password.errors.push({
          show: true,
          message: this.$t('error.distinctPassword'),
        });
        this.form.password.hasError = true;
        this.form.password.errors.push({
          show: true,
          message: this.$t('error.distinctPassword'),
        });
        validado = false;
      }
      return validado;
    },
    cleanForm: function () {
      this.form = {
        password: {
          hasError: false,
          errors: [],
        },
        confirm_password: {
          hasError: false,
          errors: [],
        },
      };
    },
    changePassword: function () {
      let changePasswordPromise = new Promise((resolve) => {
        this.updateUsuarios(resolve, this.user);
      });
      changePasswordPromise.then((data) => {
        this.$emit('save');
        this.$emit('close');
      });
    },
  },
};
</script>

<style lang="scss">
#modal-change-password {
  .form-error-box.min-height {
    min-height: 0;
  }
}
</style>
