<template>
  <div class="filter-container">
    <proveedores-selector />
    <laboratorios-selector />
    <tipo-articulo-selector />
    <categorias-selector />
    <subfamilias-selector />
  </div>
</template>

<script>
import ProveedoresSelector from './selectoresFiltros/ProveedoresSelector.vue';
import LaboratoriosSelector from './selectoresFiltros/LaboratoriosSelector.vue';
import TipoArticuloSelector from './selectoresFiltros/TipoArticuloSelector.vue';
import CategoriasSelector from './selectoresFiltros/CategoriaSelector.vue';
import SubfamiliasSelector from './selectoresFiltros/SubfamiliasSelector.vue';

export default {
  name: 'FiltroGeneral',
  components: {
    ProveedoresSelector,
    LaboratoriosSelector,
    TipoArticuloSelector,
    CategoriasSelector,
    SubfamiliasSelector,
  },
  methods: {},
};
</script>

<style scoped>
.filter-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  width: 100%;
}
</style>
