var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('pageTitle',{staticClass:"mb-2",attrs:{"title":_vm.title,"arrow-back":false,"link":"Exportar a excel"}}),_c('DataTableNaf',{attrs:{"fields":_vm.columns,"items":_vm.items,"per-page":5},scopedSlots:_vm._u([{key:"cell(prov)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.articulo))])]}},{key:"cell(total)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.total))])]}},{key:"cell(uds)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.uds))])]}},{key:"cell(desc)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.desc))])]}},{key:"cell(comp)",fn:function({ item }){return [_c('span',{style:({
          color:
            item.comp >= 100
              ? 'var(--fc-brand-02)'
              : item.comp < 100
                ? 'var(--fc-danger)'
                : '',
        })},[_vm._v(" "+_vm._s(item.comp)+"%")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }