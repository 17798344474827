<template>
  <div
    :id="`nav-item-${
      item.url
        ? item.url.split('/').join('')
        : item.text.split(' ').join('').toLowerCase()
    }`"
    class="nav-item"
    :class="{
      childs: hasChilds,
      'has-active-path': hasActivePath,
    }"
  >
    <a
      v-if="target"
      class="nav-link native-link"
      :target="target"
      :href="itemUrl"
      :class="{
        'has-no-link': !item.url,
      }"
      @click="onClickLink(itemUrl)"
    >
      <span v-if="item.icon && !isChild" :class="item.icon" class="nav-icon">
      </span>
      <span class="nav-text"> {{ item.text }} </span>
    </a>
    <a
      v-else-if="hasChilds"
      class="nav-link native-link"
      :target="target"
      @click="handleClickParent"
    >
      <span v-if="item.icon && !isChild" :class="item.icon" class="nav-icon">
      </span>
      <span class="nav-text"> {{ item.text }} </span>
      <span
        v-if="hasChilds"
        class="dropdown-chevron"
        :class="{
          'icon-chevron-up-outline': showChilds,
          'icon-chevron-down-outline': !showChilds,
        }"
      />
    </a>
    <router-link
      v-else-if="!itemUrl.startsWith('http')"
      class="nav-link router-link"
      :to="normalizedUrl(itemUrl)"
      :class="{
        'has-no-link': !item.url,
      }"
      @click.native="onClickLink(itemUrl)"
    >
      <span
        v-if="item.icon && !isChild"
        :class="item.icon"
        class="nav-icon"
      ></span>
      <span
        v-else-if="item.icon && isChild"
        :class="item.icon"
        class="nav-icon is-child"
      ></span>
      <span
        class="nav-text"
        :class="{ 'nav-text': !isChild, 'nav-child-text': isChild }"
      >
        {{ item.text }}</span
      >
    </router-link>
    <a
      v-else
      class="nav-link native-link"
      :href="itemUrl"
      :class="{
        'has-no-link': !item.url,
      }"
      @click="onClickLink(itemUrl)"
    >
      <span
        v-if="item.icon && !isChild"
        :style="item.linkStyle"
        :class="item.icon"
        class="nav-icon"
      >
      </span>
      <span :style="item.linkStyle" class="nav-text">{{ item.text }}</span>
    </a>
    <div v-if="hasChilds && showChilds" class="slot-div">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import NavItem from 'frontendcommon/src/components/design-system/NavItem.vue';
export default {
  name: 'NavItemNaf',
  extends: NavItem,
  data() {
    return {
      showChilds: false,
    };
  },
  computed: {
    hasChilds: function () {
      return this.childs.length > 0;
    },
  },
  methods: {
    handleClickParent: function () {
      this.showChilds = !this.showChilds;
    },
    normalizedUrl(url) {
      if (url && !url.startsWith('/')) {
        return '/' + url;
      }
      return url;
    },
  },
};
</script>
<style lang="scss">
.dropdown-chevron {
  font-size: 20px;
  vertical-align: middle;
}
.childs {
  display: inline;
}

.is-child {
  margin-left: 25px;
}
@media screen and (min-width: 992px) and (max-width: 1300px) {
  .slot-div {
    position: absolute;
    width: 80px;
  }
  .is-child {
    margin-left: 0px;
  }
}

.nav-item .nav-item .nav-text {
  margin: 0 !important;
}
</style>
