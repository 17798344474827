<template>
  <modal size="xl" @hide="$emit('close')">
    <template>
      <card-cabecera :transfer="transfer" />
      <div v-if="items.length" class="pt-5" :style="colorStyles">
        <DataTableNaf
          :fields="columns"
          :items="items"
          :per-page="10"
          grid-cols="2fr 3fr 2fr 2fr 2fr 2fr "
        >
          <template #cell(codigo_articulo)="{ item }">
            <span>{{ item.codigo_articulo }}</span>
          </template>
          <template #cell(nombre_articulo)="{ item }">
            <span>{{ item.nombre_articulo }}</span>
          </template>

          <template #cell(ud_solicitadas)="{ item }">
            <span>{{ item.ud_solicitadas }}</span>
          </template>
          <template #cell(ud_servidas)="{ item }">
            <span>{{ item.ud_servidas }}</span>
          </template>
          <template #cell(importe_bruto)="{ item }">
            <span>{{ item.importe_bruto | formatPrice }}</span>
          </template>
          <template #cell(importe_neto)="{ item }">
            <span>{{ item.importe_neto | formatPrice }}</span>
          </template>
        </DataTableNaf>
      </div>
    </template>
  </modal>
</template>

<script>
import home from '@/api/home.api';
import colors from '@/mixins/colors.js';
import CardCabecera from './CardCabecera.vue';
import { formatPrice, formatDate } from '@/filters/formats';
export default {
  name: 'ModalDetalleLinea',
  components: {
    CardCabecera,
  },
  filters: {
    formatPrice,
    formatDate,
  },
  extends: home,
  mixins: [colors],
  props: {
    transfer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      columns: [
        {
          key: 'codigo_articulo',
          sortable: true,
          label: 'Código artículo',
          class: 'justify-content-center',
        },
        {
          key: 'nombre_articulo',
          sortable: true,
          label: 'Nombre artículo',
        },
        {
          key: 'ud_solicitadas',
          sortable: true,
          label: 'Unidades contratadas',
          class: 'justify-content-center',
        },
        {
          key: 'ud_servidas',
          sortable: true,
          label: 'Unidades servidas',
          class: 'justify-content-center',
        },
        {
          key: 'importe_bruto',
          sortable: true,
          label: 'Importe bruto',
          class: 'justify-content-center',
        },
        {
          key: 'importe_neto',
          sortable: true,
          label: 'Importe neto',
          class: 'justify-content-center',
        },
      ],
    };
  },
  created() {
    this.loadDetalle();
  },
  methods: {
    loadDetalle: function () {
      this.items = [];

      let searchPromise = new Promise((resolve) => {
        this.getDetalle(resolve, this.transfer);
      });
      searchPromise
        .then((data) => {
          data.forEach((item) => {
            this.items.push({
              codigo_articulo: item.articulo.COD_NACIONAL_NUEVO_MOD,
              nombre_articulo: item.articulo.DESCRIPCION_CORTA,
              ud_solicitadas: item.UD_SOLICITADAS,
              ud_servidas: item.UD_SERVIDAS,
              importe_bruto: item.IMPORTE_BRUTO,
              importe_neto: item.IMPORTE_NETO,
            });
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.form {
  overflow: visible;
}
</style>
