<template>
  <div class="multiple-select-custom">
    <div
      class="multiple-select-custom-label-container"
      @click="collapse = !collapse"
    >
      <div class="multiple-select-custom-icon-text">
        <p>{{ label }}</p>
      </div>
      <tooltip
        v-if="getNumberElementsSelected > 0"
        position="bottom"
        show-event="hover"
      >
        <span class="circle-number">+{{ getNumberElementsSelected }}</span>
        <template #content>
          <span v-html="getFilterNameElementsSelected"></span>
        </template>
      </tooltip>
      <span
        class="icon-chevron-down-outline"
        :class="{ open: collapse }"
      ></span>
    </div>

    <div v-if="collapse" class="multiple-select-without-suboptions">
      <multiple-select-inner-check :suboptions="options" @checked="checked" />
    </div>
  </div>
</template>

<script>
import MultipleSelectInnerCheck from './multipleSelectInner/MultipleSelectInnerCheck';
import { debounce } from 'lodash';

export default {
  name: 'CheckSelectorCollapse',
  components: {
    MultipleSelectInnerCheck,
  },
  props: ['icon', 'label', 'options', 'filterOpened'],
  data() {
    return {
      collapse: false,
    };
  },
  computed: {
    getNumberElementsSelected() {
      let count = 0;
      this.options.forEach((item) => {
        if (item.suboptions) {
          count += item.suboptions.filter((subitem) => subitem.selected).length;
        } else if (item.selected) {
          count++;
        }
      });
      return count;
    },
    getFilterNameElementsSelected() {
      return this.options
        .map((item) => {
          const selectedSubitems = item.suboptions
            ? item.suboptions
                .filter((subitem) => subitem.selected)
                .map((subitem) => subitem.text)
            : [];
          if (selectedSubitems.length > 0) {
            return `${item.text}: ${selectedSubitems.join(' / ')}`;
          }
          return null;
        })
        .filter((text) => text !== null)
        .join('<br />');
    },
  },
  watch: {
    collapse(newValue) {
      if (newValue) {
        this.$emit('openFilter', this.label);
      }
    },
    filterOpened() {
      this.collapse =
        this.filterOpened !== this.label && this.collapse
          ? false
          : this.collapse;
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    // Usar debounce en el método checked
    checked: debounce(function (value) {
      this.$emit('checked', value);
    }, 300), // 300ms de retraso para evitar actualizaciones rápidas

    // Método para cerrar el menú al hacer clic fuera
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.collapse = false;
      }
    },
  },
};
</script>

<style lang="scss">
.multiple-select-custom {
  width: 180px;
  position: relative;
  height: 45px;
  padding: 0 15px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  .multiple-select-custom-label-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .circle-number {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: var(--fc-brand-01-medium);
    color: var(--fc-brand-01);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 55px;
    text-align: center;
    margin-right: 10px;
  }
  .multiple-select-custom-icon-text {
    display: flex;
    align-items: center;
    p {
      margin: 0 10px 0 5px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: var(--fc-brand-03);
    }
    span {
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      line-height: 15px;
      color: var(--fc-brand-03);
    }
  }
  .icon-chevron-down-outline {
    cursor: pointer;
    font-size: 22px;
    line-height: 22px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    &.open {
      transform: rotate(180deg);
    }
  }

  .multitple-select-custom-options {
    z-index: 999;
    position: absolute;
    top: 50px;
    left: 0;
    width: 180px;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    box-shadow: 0px 3px 10px rgba(216, 213, 213, 0.25);
    .multiple-select-custom-option {
      cursor: pointer;
      width: 100%;
      padding: 0 8px;
      @media screen and (min-width: 480px) {
        position: relative;
      }
      .multiple-select-custom-text {
        width: 100%;
        padding: 9px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          margin: 0;
          color: var(--fc-brand-02);
        }
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          line-height: 24px;
          color: var(--fc-brand-03);
        }
      }
      &:hover {
        .multiple-select-custom-text {
          background-color: var(--fc-brand-01);
          * {
            color: #fff;
          }
        }
        .multiple-select-custom-subtoptions {
          display: block;
        }
      }
      .multiple-select-custom-subtoptions {
        display: none;
        z-index: 999;
        position: absolute;
        min-width: 150px;
        max-height: 500px;
        overflow-y: scroll;
        width: auto;
        top: 0px;
        left: 180px;
        padding: 15px;
        @media screen and (max-width: 480px) {
          left: 0px;
          bottom: 0px;
          transform: translateY(100%);
        }
        background: #fafafa;
        border: 1px solid #e2e2e2;
        border-radius: 6px;
        box-shadow: 0px 3px 10px rgba(216, 213, 213, 0.25);
      }
    }
  }

  .multiple-select-without-suboptions {
    z-index: 999;
    position: absolute;
    top: 50px;
    left: 0;
    width: 180px;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    box-shadow: 0px 3px 10px rgba(216, 213, 213, 0.25);
    .selected {
      background-color: var(--fc-brand-01);
      color: #fff;
    }
    .select-without-suboption-option {
      cursor: pointer;
      width: 100%;
      padding: 9px 12px;
      border-radius: 6px;
      -webkit-transition: 0.3s;
      transition: 0.3s;

      &:hover {
        color: #fff;
        background-color: var(--fc-brand-01);
      }
      input {
        margin: 5px 5px 0 0;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        accent-color: var(--fc-brand-01);
      }
    }
  }
}
</style>
