import es from './es.json';

export const defaultLocale = 'es';

export const languages = {
  es: es,
};

export const numberFormats = {
  es: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      redondear: true,
    },
    percent: {
      style: 'percent',
    },
    percentDecimal: {
      style: 'percent',
      minimumFractionDigits: 2, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 2,
      strip_insignificant_zeros: true,
      redondear: true,
    },
    percentNoMultiply: {
      style: 'decimal',
      minimumFractionDigits: 0, // Optionally set the number of decimal places
      maximumFractionDigits: 0, // Optional
    },
    numero: {
      style: 'decimal',
      minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 0,
      redondear: true,
      esUnidades: false,
    },
    dias: {
      style: 'decimal',
      minimumFractionDigits: 2, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 2,
      redondear: true,
      esUnidades: false,
      esDias: true,
    },
    unidades: {
      style: 'decimal',
      minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 0,
      redondear: true,
      esUnidades: true,
    },
    unidadesChart: {
      style: 'decimal',
      minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 0,
      redondear: true,
      minimizarMiles: true,
      esUnidades: true,
    },
    percentChart: {
      style: 'percent',
      minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 0,
      redondear: true,
      minimizarMiles: true,
    },
    codigo: {
      style: 'decimal',
      useGrouping: false,
      minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 2,
      redondear: true,
    },
  },
};

export const dateTimeFormats = {
  es: {
    periodo: {
      year: 'numeric',
      month: 'short',
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
    longSecond: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      second: '2-digit',
      minute: 'numeric',
    },
  },
};
