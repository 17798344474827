<template>
  <div>
    <check-selector-collapse
      :icon="icon"
      :label="label"
      :options="options"
      @checked="handleChecked"
    />
  </div>
</template>

<script>
import CheckSelectorCollapse from '../../../../commons/forms/CheckSelectorCollapse.vue';
import proveedores from '@/api/proveedores.api';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';

export default {
  name: 'ProveedoresSelector',
  components: { CheckSelectorCollapse },
  extends: proveedores,
  props: {
    label: {
      type: String,
      default: 'Proveedores',
    },
    icon: {
      type: String,
      default: 'icon-pharmacy',
    },
  },
  data() {
    return {
      options: [],
      selectedItems: [],
      selectedItemsSet: new Set(),
    };
  },
  computed: {
    ...mapState('filters', ['filtros']),
  },
  watch: {
    'filtros.proveedores': function () {
      this.syncSelectedItemsWithStore();
    },
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    loadItems() {
      let loadPromise = new Promise((resolve) => {
        this.getProveedoresSellout(resolve, this.request);
      });

      loadPromise.then((data) => {
        this.options = data.map((item) => ({
          text: item.nombre,
          value: item.id_proveedor_sellout,
          selected: false,
        }));
      });
    },
    syncSelectedItemsWithStore() {
      const proveedoressSeleccionados = this.filtros.proveedores || [];

      const selectedItemsSet = new Set();

      proveedoressSeleccionados.forEach((proveedor) => {
        selectedItemsSet.add(String(proveedor.codigo));
        selectedItemsSet.add(String(proveedor.value));
      });

      this.options = this.options.map((item) => {
        item.selected = selectedItemsSet.has(String(item.value));
        return item;
      });
    },
    handleChecked(newSelectedItems) {
      const proveedoressSeleccionados = this.filtros.proveedores || [];

      if (!Array.isArray(newSelectedItems)) {
        newSelectedItems = [newSelectedItems];
      }

      newSelectedItems.forEach((selected) => {
        const valueToCheck = String(selected.value);

        if (selected.selected) {
          this.selectedItemsSet.add(valueToCheck);
        } else {
          this.selectedItemsSet.delete(valueToCheck);
        }
      });

      let selectedItemsArray = Array.from(this.selectedItemsSet);

      proveedoressSeleccionados.forEach((item) => {
        const valueToCheck = item.value
          ? String(item.value)
          : String(item.codigo);

        if (!selectedItemsArray.includes(valueToCheck)) {
          selectedItemsArray.push(valueToCheck);
        }
      });

      newSelectedItems.forEach((selected) => {
        if (!selected.selected) {
          const valueToCheck = String(selected.value);
          selectedItemsArray = selectedItemsArray.filter(
            (item) => item !== valueToCheck
          );
        }
      });

      const selectedItems = selectedItemsArray.map((value) => ({
        value,
        selected: true,
      }));

      this.updateFiltros({
        filtro: 'proveedores',
        items: selectedItems,
      });
    },

    ...mapActions('filters', ['updateFiltros']),
  },
};
</script>
