<template>
  <div
    class="loader-naf animation--wave-naf shape--rect shape--flat"
    :style="{ width: width, height: height, borderRadius: borderRadius }"
  ></div>
</template>

<script>
export default {
  name: 'LoaderNaf',
  props: ['width', 'height', 'borderRadius'],
};
</script>

<style lang="scss" scoped>
.loader-naf {
  overflow: hidden;
  position: relative;
  cursor: wait;
  --gradient-color: rgba(255, 255, 255, 0.5);
}

.loader-naf::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.animation--wave-naf::before {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 90%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>
