<template>
  <div class="mb-4">
    <pageTitle
      :title="title"
      :arrow-back="false"
      link="Exportar a excel"
      link-to=""
      class="mb-2"
    />

    <DataTableNaf :fields="columns" :items="items" :per-page="5">
      <template #cell(prov)="{ item }">
        <span>{{ item.prov }}</span>
      </template>

      <template #cell(total)="{ item }">
        <span>{{ item.total }}</span>
      </template>

      <template #cell(uds)="{ item }">
        <span>{{ item.uds }}</span>
      </template>

      <template #cell(desc)="{ item }">
        <span>{{ item.desc }}</span>
      </template>

      <template #cell(comp)="{ item }">
        <span
          :style="{
            color:
              item.comp >= 100
                ? 'var(--fc-brand-02)'
                : item.comp < 100
                  ? 'var(--fc-danger)'
                  : '',
          }"
        >
          {{ item.comp }}%</span
        >
      </template>
    </DataTableNaf>
  </div>
</template>

<script>
export default {
  name: 'TablaDtoProveedor',
  data() {
    return {
      title: 'Comparación descuentos por proveedor',
      columns: [
        {
          key: 'prov',
          sortable: true,
          label: 'Proveedor',
        },
        {
          key: 'total',
          sortable: true,
          label: 'Total compras',
          class: 'justify-content-center',
        },
        {
          key: 'uds',
          sortable: true,
          label: 'Unidades',
          class: 'justify-content-center',
        },
        {
          key: 'desc',
          sortable: true,
          label: 'Descuento acumulado',
          class: 'justify-content-center',
        },
        {
          key: 'comp',
          sortable: true,
          label: 'Comparación',
          class: 'justify-content-center',
        },
      ],
      items: [
        {
          prov: 'Proveedor 1',
          total: '10.000€',
          uds: 10.0,
          desc: '1.000€ (10%)',
          comp: 97.78,
        },
        {
          prov: 'Proveedor 2',
          total: '10.000€',
          uds: 10.0,
          desc: '1.000€ (10%)',
          comp: 57.98,
        },
        {
          prov: 'Proveedor 3',
          total: '10.000€',
          uds: 10.0,
          desc: '1.000€ (10%)',
          comp: 105.31,
        },
        {
          prov: 'Proveedor 4',
          total: '10.000€',
          uds: 10.0,
          desc: '1.000€ (10%)',
          comp: 150.5,
        },
        {
          prov: 'Proveedor 5',
          total: '10.000€',
          uds: 10.0,
          desc: '1.000€ (10%)',
          comp: 39.04,
        },
        {
          prov: 'Proveedor 6',
          total: '10.000€',
          uds: 10.0,
          desc: '1.000€ (10%)',
          comp: 97.78,
        },
        {
          prov: 'Proveedor 7',
          total: '10.000€',
          uds: 10.0,
          desc: '1.000€ (10%)',
          comp: 57.98,
        },
        {
          prov: 'Proveedor 8',
          total: '10.000€',
          uds: 10.0,
          desc: '1.000€ (10%)',
          comp: 105.31,
        },
        {
          prov: 'Proveedor 9',
          total: '10.000€',
          uds: 10.0,
          desc: '1.000€ (10%)',
          comp: 150.5,
        },
        {
          prov: 'Proveedor 10',
          total: '10.000€',
          uds: 10.0,
          desc: '1.000€ (10%)',
          comp: 39.04,
        },
      ],
    };
  },
};
</script>
