<template>
  <div class="user-box">
    <tooltip show-event="click" :enabled="!layout.isXL">
      <div class="avatar">
        <div
          class="avatar-inner d-flex justify-content-center"
          :style="[avatar ? avatarStyle : {}]"
        >
          <h4 v-if="!avatar" class="align-self-center">
            {{ lettersComputed }}
          </h4>
        </div>
      </div>
      <template #content>
        <div style="white-space: nowrap">
          <span class="bold-medium d-block fullname">{{
            fullNameComputed
          }}</span>
          <span v-if="farmaNameComputed" class="regular-small farmaname">{{
            farmaNameComputed
          }}</span>
        </div>
      </template>
      <div class="user-info d-flex flex-column justify-content-center">
        <span class="bold-medium d-block fullname">{{ fullNameComputed }}</span>
        <span v-if="farmaNameComputed" class="regular-small farmaname">{{
          farmaNameComputed
        }}</span>
      </div>
    </tooltip>
  </div>
</template>

<script>
import UserBox from 'frontendcommon/src/components/design-system/UserBox.vue';
export default {
  name: 'Userboxnaf',
  extends: UserBox,
};
</script>
