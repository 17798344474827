<template>
  <div class="multiple-select-custom">
    <div
      class="multiple-select-custom-label-container"
      @click="collapse = !collapse"
    >
      <tooltip
        v-if="getNumberElementsSelected > 0"
        position="bottom"
        show-event="hover"
      >
        <span class="circle-number">+{{ getNumberElementsSelected }}</span>
        <template #content>
          <span v-html="getFilterNameElementsSelected"></span>
        </template>
      </tooltip>

      <span
        class="icon-chevron-down-outline"
        :class="{ open: collapse }"
      ></span>
    </div>

    <div v-if="collapse" class="multiple-select-without-suboptions">
      <multiple-select-inner-check-load
        :options="options"
        :load-method="loadMoreMethod"
        @checked="checked"
        @input="updateOptions"
      />
    </div>
  </div>
</template>

<script>
import MultipleSelectInnerCheckLoad from './multipleSelectInner/MultipleSelectInnerCheckLoad';

export default {
  name: 'CheckSelectorCollapseLoad',
  components: {
    MultipleSelectInnerCheckLoad,
  },
  props: {
    loadMoreMethod: {
      type: Function,
      required: true,
    },
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      collapse: false,
      filteredOptions: [],
    };
  },
  computed: {
    getNumberElementsSelected: function () {
      let number = 0;
      if (this.options.length) {
        this.options.forEach((item) => {
          if (item.suboptions) {
            item.suboptions.forEach((subitem) => {
              if (subitem.selected) number++;
            });
          } else {
            if (item.selected) number++;
          }
        });
      }

      return number;
    },
    getFilterNameElementsSelected: function () {
      let text = [];
      if (this.options) {
        for (let i = 0; i < this.options.length; i++) {
          let item = this.options[i];
          let innerText = [];
          if (item.suboptions) {
            for (let j = 0; j < item.suboptions.length; j++) {
              let subitem = item.suboptions[j];
              if (subitem.selected) {
                innerText.push(subitem.text);
              }
            }
          }
          if (innerText.length > 0) {
            text.push(item.text + ': ' + innerText.join(' / '));
          }
        }
      }
      return text.join('<br />');
    },
  },
  watch: {
    async collapse(newValue) {
      if (newValue) {
        this.$emit('openFilter', this.label);
      }
    },
    // filterOpened() {
    //     this.collapse = this.filterOpened != this.label && this.collapse ? false : this.collapse;
    // },
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },

  methods: {
    selected: function (value) {
      this.$emit('selected', {
        item: value,
        index: index,
      });
    },
    checked: function (value) {
      // console.log(value);
      this.$emit('checked', value);
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.collapse = false;
      }
    },
    updateOptions: function (data) {
      this.options = data;
    },
  },
};
</script>

<style lang="scss">
.multiple-select-custom {
  position: relative;
  height: 45px;
  padding: 0 15px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .multiple-select-custom-label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .circle-number {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: var(--fc-brand-01-medium);
    color: var(--fc-brand-01);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 55px;
    text-align: center;
    margin-right: 10px;
  }

  .multiple-select-custom-icon-text {
    display: flex;
    align-items: center;

    p {
      margin: 0 10px 0 5px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: var(--fc-brand-03);
    }

    span {
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      line-height: 15px;
      color: var(--fc-brand-03);
    }
  }

  .icon-chevron-down-outline {
    cursor: pointer;
    font-size: 22px;
    line-height: 22px;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    &.open {
      transform: rotate(180deg);
    }
  }

  .multitple-select-custom-options {
    z-index: 999;
    position: absolute;
    top: 50px;
    left: 0;
    width: 180px;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    box-shadow: 0px 3px 10px rgba(216, 213, 213, 0.25);

    .multiple-select-custom-option {
      cursor: pointer;
      width: 100%;
      padding: 0 8px;

      @media screen and (min-width: 480px) {
        position: relative;
      }

      .multiple-select-custom-text {
        width: 100%;
        padding: 9px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        -webkit-transition: 0.3s;
        transition: 0.3s;

        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          margin: 0;
          color: var(--fc-brand-02);
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          line-height: 24px;
          color: var(--fc-brand-03);
        }
      }

      &:hover {
        .multiple-select-custom-text {
          background-color: var(--fc-brand-01);

          * {
            color: #fff;
          }
        }

        .multiple-select-custom-subtoptions {
          display: block;
        }
      }

      .multiple-select-custom-subtoptions {
        display: none;
        z-index: 999;
        position: absolute;
        min-width: 150px;
        max-height: 500px;
        overflow-y: scroll;
        width: auto;
        top: 0px;
        left: 180px;
        padding: 15px;

        @media screen and (max-width: 480px) {
          left: 0px;
          bottom: 0px;
          transform: translateY(100%);
        }

        background: #fafafa;
        border: 1px solid #e2e2e2;
        border-radius: 6px;
        box-shadow: 0px 3px 10px rgba(216, 213, 213, 0.25);
      }
    }
  }

  .multiple-select-without-suboptions {
    z-index: 999;
    position: absolute;
    top: 50px;
    left: 0;
    width: 180px;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    box-shadow: 0px 3px 10px rgba(216, 213, 213, 0.25);

    .selected {
      background-color: var(--fc-brand-01);
      color: #fff;
    }

    .select-without-suboption-option {
      cursor: pointer;
      width: 100%;
      padding: 9px 12px;
      border-radius: 6px;
      -webkit-transition: 0.3s;
      transition: 0.3s;

      &:hover {
        color: #fff;
        background-color: var(--fc-brand-01);
      }

      input {
        margin: 5px 5px 0 0;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        accent-color: var(--fc-brand-01);
      }
    }
  }
}
</style>
