import store from '../store';
import { saveAs } from 'file-saver';

const accessToken = store.getters.getAccessToken;
import httpClient from './httpClient';

const END_POINT = '/sellout/';
export default {
  methods: {
    getDatosHistorico: function (resolve, request) {
      httpClient
        .post(END_POINT + 'sellout/', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(Promise.reject(error.response));
        });
    },
    getComprasCategorizacion: function (
      resolve,
      catFilter,
      nivelCategorizacion
    ) {
      httpClient
        .post(END_POINT + 'comprasCategorizacion', {
          catFilter: catFilter,
          nivelCategorizacion: nivelCategorizacion,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
