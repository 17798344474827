<template>
  <div>
    <div v-if="filterOptions" class="d-flex align-items-center">
      <template v-for="(filter, index) in filterOptions" class="mr-2">
        <h5>{{ filter.title }}</h5>
        <div class="d-flex">
          <component
            :is="filter.type || 'input-box'"
            v-if="filter"
            v-bind="filter.props"
            v-model="filter.default"
            class="ml-3 mr-5"
            :value="filter.type === 'date-picker' ? null : filters[filter.key]"
            :label="filter.label"
            :options="filter.options"
            :placeholder="filter.placeholder"
            :clear-icon="filter.clear == undefined ? true : filter.clear"
            @input="inputFilter(filter, $event)"
            @clear="inputFilter(filter)"
            @click="$emit('click', filter)"
            @checked="inputFilter(filter, $event)"
            @selected="inputFilter(filter, $event)"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FilterGroup',
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    filterOptions: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    filters: {},
    cambio: '',
  }),

  methods: {
    inputFilter(field, val) {
      if (typeof val === 'string')
        val = val == null || val === '' ? undefined : val;
      //   if (this.filterOptions[field.key].type === 'date-picker' && val) {
      //     val = new Date(this.dateUserToServer(val))
      //   }
      if (val != undefined) {
        this.filters = { ...this.filters, [field.key]: val };
      } else {
        delete this.filters[field.key];
      }

      this.$emit('filtros', this.filters);
    },
  },
};
</script>
