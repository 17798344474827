<template>
  <div class="multiple-select-custom-subtoptions">
    <div class="multiple-select-custom-subtoptions-input">
      <div
        v-for="option in suboptions"
        :key="option.value"
        class="multiple-select-check"
      >
        <InputBox
          type="text"
          :value="option.value"
          :label="option.text"
          @change="change(option)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleSelectInnerInput',
  props: ['suboptions'],
  methods: {
    checked: function (option) {
      this.$emit('change', option);
    },
  },
};
</script>
