<template>
  <modal size="sm" padding-size="sm" @hide="$emit('close')">
    <template>
      <div id="modal-change-phone">
        <h3>Número de teléfono</h3>
        <p>Indica tu número de teléfono de contacto</p>

        <input-box
          v-model="phone"
          label="Teléfono principal"
          type="number"
          class="mt-5 mb-5"
        />

        <div class="d-flex">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('generals.cancel')
          }}</b-button>
          <b-button variant="primary" class="ml-3" @click="$emit('close')">{{
            $t('generals.apply')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ModalChangePhone',
  data() {
    return {
      phone: '',
    };
  },
  methods: {},
};
</script>

<style lang="scss">
#modal-change-phone {
  .form-error-box.min-height {
    min-height: 0;
  }
}
</style>
