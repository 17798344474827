<template>
  <div class="multiple-select-custom-subtoptions">
    <div class="multiple-select-custom-subtoptions-check">
      <div class="multiple-select-input-search">
        <input v-model="search" type="text" placeholder="Buscar..." />
        <span data-v-7bda208a="" class="icon icon-search-outline"></span>
        <input type="checkbox" class="check-all" @click="checkedAll()" />
      </div>
      <div
        ref="scrollContainer"
        class="multiple-select-checks-options"
        @scroll="handleScroll"
      >
        <div
          v-for="(option, key) in searchSuboptions"
          :key="key"
          class="multiple-select-check"
        >
          <input
            type="checkbox"
            :value="option.value"
            :checked="option.selected"
            @click="checked(option)"
          />
          {{ option.text }}
        </div>
        <div v-if="isLoading" class="loading-message">Cargando...</div>
        <!-- <div v-else-if="!hasMoreData" class="loading-message">No hay más resultados</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleSelectInnerCheckLoad',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    loadMethod: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      internalValue: this.value,
      search: '',
      localOptions: [],
      checkedAllOn: false,
      isLoading: false,
      hasMoreData: true,
      page: 1,
    };
  },
  computed: {
    searchSuboptions: function () {
      this.isLoading = false;
      return this.options.filter((item) => {
        return item.text.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
      });
    },
  },
  watch: {
    search(newValue) {
      this.isLoading = true;
      // this.options=[]
      this.page = 1;
      let request = {
        page: this.page,
        search: newValue,
      };
      this.loadData(request);
      // this.$emit('loadMoreData',request);
    },
    localOptions(newOptions) {
      let datosFiltrados = newOptions.filter((nuevoDato) => {
        return !this.options.some(
          (elemento) => elemento.value === nuevoDato.value
        );
      });
      datosFiltrados.forEach((item) => {
        this.options.push(item);
      });

      this.$emit('input', this.options);
      // this.options = [...datosFiltrados];
      this.isLoading = false;
    },
  },
  created() {
    this.isLoading = true;
    this.loadData();
  },
  methods: {
    async loadData(data) {
      this.localOptions = await this.loadMethod(data);

      //  nuevasOpciones.forEach((item) => {
      //     this.options.push(item);
      //   });
      // this.options=nuevasOpciones;
    },
    checkedAll: function () {
      this.checkedAllOn = !this.checkedAllOn;
      let vm = this;
      this.options.forEach(function (d) {
        d.selected = vm.checkedAllOn;
        vm.$emit('checked', d);
      });
    },
    checked: function (option) {
      option.selected = !option.selected;
      this.$emit('checked', option);
      this.options = this.options.sort((a, b) =>
        a.selected && b.selected ? 0 : a.selected && !b.selected ? -1 : 1
      );
      this.$emit('input', this.options);
    },
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollTop = scrollContainer.scrollTop;
      const scrollHeight = scrollContainer.scrollHeight;
      const clientHeight = scrollContainer.clientHeight;
      // console.log("SCROLLL", scrollTop, clientHeight, scrollHeight, scrollContainer)

      if (
        scrollTop + clientHeight >= scrollHeight &&
        this.hasMoreData &&
        !this.isLoading
      ) {
        this.isLoading = true;
        this.page += 1;
        let request = {
          page: this.page,
          search: this.search,
        };
        this.loadData(request);
        // this.$emit('loadMoreData',request); // Emitir evento para cargar más datos al final de la lista
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-select-custom-subtoptions-check {
  // overflow-y: auto;
  .multiple-select-input-search {
    width: 100%;
    position: relative;

    input {
      width: calc(100% - 25px);
      height: 30px;
      padding: 0 30px 0 15px;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      box-shadow: none;
      font-size: 13px;
      line-height: 20px;
      color: #3d3f49;
    }

    span {
      position: absolute;
      top: 4px;
      right: 30px;
    }
  }

  .multiple-select-checks-options {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 340px;
  }

  input.check-all {
    margin: 5px 0 5px 5px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    accent-color: var(--fc-brand-01);
  }

  .multiple-select-check {
    display: flex;
    align-items: flex-start;
    // width: 215px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #3d3f49;

    input {
      margin: 5px 5px 0 0;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      accent-color: var(--fc-brand-01);
    }
  }
}
</style>
