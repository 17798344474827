<template>
  <div>
    <toast
      v-if="showToast"
      class="mt-2"
      type="success-filled"
      description="Exclusión guardada correctamente"
      @timeout="handleCloseToast"
      @close="handleCloseToast"
    />

    <card>
      <template #content>
        <b-row>
          <b-col>
            <h4>{{ title }}</h4>
          </b-col>
          <b-col v-if="!isLab() && !isCoop()" class="text-right">
            <b-button variant="primary" @click="crear">{{
              $t('button.create')
            }}</b-button>
          </b-col>
        </b-row>
      </template>
      <template>
        <div v-if="items.length">
          <DataTableNaf
            :fields="columns"
            :items="items"
            :per-page="10"
            grid-cols="2fr 2fr 2fr 1fr"
          >
            <template #cell(menu)="{ item }">
              <span>{{ item.menu.nombre }}</span>
            </template>
            <template #cell(user)="{ item }">
              <span v-if="item.user">{{ item.user.username }}</span>
              <span v-else>{{ '-' }}</span>
            </template>
            <template #cell(rol)="{ item }">
              <span v-if="item.rol">{{ item.rol.descripcion }}</span>
              <span v-else>{{ '-' }}</span>
            </template>
            <template #cell(icons)="{ item }">
              <div class="d-flex justify-content-between">
                <div v-if="!isLab() && !isCoop()">
                  <span
                    class="icon icon-trash-outline"
                    @click="openConfirmacion(item.id)"
                  ></span>
                </div>
              </div>
            </template>
          </DataTableNaf>
        </div>
        <card-item-simple v-if="!items.length">
          <template #description>
            <div>{{ $t('sinResultados') }}</div>
          </template>
        </card-item-simple>
      </template>
    </card>
    <modal-permisos v-if="showModal" @close="close" @save="loadItems" />
    <modal-confirmacion
      v-if="showConfirmation"
      @close="close"
      @confirm="borrar"
    />
  </div>
</template>

<script>
import menu from '@/api/menu.api';
import colors from '@/mixins/colors.js';
import { mapGetters } from 'vuex';
import ModalPermisos from './ModalPermisos.vue';

export default {
  name: 'ListadoPermisos',
  components: {
    ModalPermisos,
  },
  extends: menu,
  mixins: [colors],
  data() {
    return {
      title: 'Menu',
      showModal: false,
      showConfirmation: false,
      showResetPassword: false,
      items: [],
      showToast: false,
      columns: [
        {
          key: 'menu',
          sortable: true,
          label: 'Menu',
        },
        {
          key: 'user',
          sortable: true,
          label: 'Usuario',
        },
        {
          key: 'rol',
          sortable: true,
          label: 'Rol',
        },
        {
          key: 'icons',
          sortable: false,
          label: '',
          class: 'justify-content-end',
        },
      ],
      request: {},
      loading: true,
      itemToDelete: null,
    };
  },
  watch: {
    request: function () {
      this.loadItems();
    },
    reload: function () {
      this.loadItems();
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    ...mapGetters(['isCoop', 'isLab']),
    loadItems: function () {
      let loadPromise = new Promise((resolve) => {
        this.getPermiso(resolve, this.request);
      });
      loadPromise.then((data) => {
        this.items = [];
        this.items = data;
      });
    },
    crear: function () {
      this.esNuevo = true;
      this.showModal = true;
    },
    borrar: function () {
      this.showConfirmation = false;
      if (this.itemToDelete) {
        let request = {
          id: this.itemToDelete,
        };
        let deletePromise = new Promise((resolve) => {
          this.deletePermiso(resolve, request);
        });
        deletePromise.then(() => {
          this.loadItems();
        });
      }
    },
    close: function () {
      this.showConfirmation = false;
      this.showModal = false;
      this.showResetPassword = false;
    },
    openConfirmacion: function (data) {
      this.itemToDelete = data;
      this.showConfirmation = true;
    },

    handleCloseToast() {
      this.showToast = false;
    },
  },
};
</script>

<style lang="scss" scope></style>
