<template lang="">
  <BaseView :title="title" class="mb-5">
    <template #content>
      <b-container fluid>
        <b-row class="mb-3">
          <b-col>
            <listado-farmacias />
          </b-col>
        </b-row>
      </b-container>
    </template>
  </BaseView>
</template>

<script>
import { ListadoFarmacias } from '@/components';
import { mapGetters } from 'vuex';
export default {
  name: 'Farmacias',
  components: {
    ListadoFarmacias,
  },
  data() {
    return {
      title: 'Farmacias',
      loading: true,
    };
  },
};
</script>

<style lang=""></style>
