<template>
  <aside :class="[layout.sidebarOpen ? 'sidebar-open' : 'sidebar-close']">
    <div class="sidebar-content">
      <section class="section-top">
        <img class="logo" :src="logoSrc" />
        <img class="logo-mini" :src="logoMiniSrc" />
      </section>
      <nav class="custom-scrollbar-small">
        <NavItemNaf
          v-for="navItem in navItems"
          :key="navItem.text"
          :childs="navItem.childs"
          :item="navItem.item"
          :target="navItem.item.target"
          :actual-path="actualPath"
          :is-child="false"
          @click="onClickNav"
        >
          <NavItemNaf
            v-for="childItem in navItem.childs"
            :key="childItem.text"
            :item="childItem"
            :target="navItem.target"
            :is-child="true"
            :actual-path="actualPath"
            @click="onClickNav"
          >
          </NavItemNaf>
        </NavItemNaf>
      </nav>
      <!-- <section class="section-bottom">
          <div class="crazy-line"></div>
          <userboxnaf @click-logout="logout" :fullName="fullName" />
        </section> -->
      <section class="section-bottom">
        <div class="crazy-line"></div>
        <user-box :full-name="fullName" @click-logout="logout" />
      </section>
    </div>
  </aside>
</template>

<script>
import Sidebar from 'frontendcommon/src/components/design-system/Sidebar.vue';
import NavItemNaf from './NavItemNaf.vue';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarNaf',
  components: {
    NavItemNaf,
  },
  extends: Sidebar,
  props: {
    logoSrc: String,
    logoMiniSrc: String,
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  methods: {
    ...mapActions(['saveAccessToken', 'destroyStore']),
    showProfile: function () {
      this.$router.push('/paneldecontrol/mi-perfil');
    },
    logout: function () {
      this.destroyStore();
      // this.$router.push('/login')
      window.location.href = process.env.BASE_URL;
    },
  },
  data() {
    return {
      fullName: '',
    };
  },
  created() {
    console.log();
    this.fullName = this.getUser.name;
  },
};
</script>

<style lang="scss">
.sidebar-content {
  .section-bottom {
    cursor: pointer;
  }
}

.nav-item.has-active-path .nav-link[data-v-0ed39c1c] {
  border-left: 6px solid var(--fc-brand-02) !important;
}

.nav-item.has-active-path .nav-link[data-v-0ed39c1c] {
  color: var(--fc-brand-02) !important;
}
.nav-item.has-active-path .nav-link[data-v-0ed39c1c]:hover {
  color: var(--fc-brand-02) !important;
}

.nav-item .nav-link[data-v-0ed39c1c] {
  padding: 20px;
}
</style>
