<template>
  <!-- <button type="button" v-on:click="onexport" class="btn btn-circle btn-icon-only excel"> </i></button> -->
  <b-button
    variant="link"
    size="sm"
    icon="icon-download-outline"
    @click="onexport"
    >Exportar a Excel</b-button
  >
</template>

<script>
import * as XLSX from 'xlsx';
export default {
  name: 'ExportExcel',
  props: {
    listado: {
      type: Array,
      default: [],
    },
    filename: {
      type: String,
      default: 'listado.xlsx',
    },
    header: {
      type: Array,
      default: null,
    },
  },
  methods: {
    //https://medium.com/devschile/exportando-a-excel-desde-un-object-con-vuejs-b251f3780134
    onexport() {
      let columns = [];
      let headerLabels = [];
      let filtered = this.listado;
      if (this.header != null) {
        //Obtenemos los nombres de las columnas y las columnas que se quieren mostrar
        //De las columnas comprobamos si existen en el listado, damos por hecho que todos los objetos tienen las mismas keys
        this.header.forEach(function (h) {
          if (h.key in filtered[0]) {
            columns.push(h.key);
            headerLabels.push(h.label);
          }
        });

        // Del listado, nos quedamos solo con la columnas que se quieren mostrar
        filtered = [];
        this.listado.forEach(function (d) {
          filtered.push(
            Object.keys(d)
              .filter(function (key) {
                return columns.includes(key);
              })
              .reduce((obj, key) => {
                obj[key] = d[key];
                return obj;
              }, {})
          );
        });
      }

      let ws = XLSX.utils.json_to_sheet(filtered, { header: columns });

      if (this.header != null) {
        let range = XLSX.utils.decode_range(ws['!ref']);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          let address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
          if (!ws[address]) continue;
          ws[address].v = headerLabels[C];
        }
      }

      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Listado');
      XLSX.writeFile(wb, this.filename);
    },
  },
};
</script>
