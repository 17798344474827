<template>
  <div class="input-custom">
    <span :class="icon"></span>
    <input v-model="localValue" type="text" :placeholder="placeholder" />
    <span
      v-if="clearIconType && localValue != ''"
      :class="['clear-icon', 'icon', clearIconType]"
      class="icon icon-close-outline"
      @click="localValue = ''"
    ></span>
  </div>
</template>

<script>
import customvmodelMixin from '@/mixins/custom.vmodel.js';
export default {
  name: 'InputCustom',
  mixins: [customvmodelMixin],
  props: ['icon', 'placeholder', 'clearIconType'],
};
</script>

<style lang="scss" scoped>
.input-custom {
  width: 100%;
  height: 50px;
  box-shadow: 0px 1px 4px rgba(188, 186, 186, 0.25);
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  input {
    width: calc(100% - 75px);
    height: 50px;
    border-radius: 5px;
    padding-left: 10px;
    border: none;
    outline: none;
    background-color: transparent;
  }
  span {
    width: 25px;
    height: 25px;
    margin-top: -12.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    line-height: 23px;
    margin-left: 5px;
    margin-top: 3px;
  }
}
</style>
