import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router/index';
import store from './store';

import { DesignSystemPlugin } from 'frontendcommon';
import GlobalComponents from './commons/index';

import {
  LayoutPlugin,
  ButtonPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormTagsPlugin,
  FormRadioPlugin,
  ModalPlugin,
  FormSelectPlugin,
  FormFilePlugin,
} from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTagsPlugin);
Vue.use(FormRadioPlugin);
Vue.use(ModalPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormFilePlugin);

Vue.use(DesignSystemPlugin);
Vue.use(GlobalComponents);

Vue.config.productionTip = false;

/* Translations */

Vue.use(VueI18n);
import {
  languages,
  dateTimeFormats,
  numberFormats,
  defaultLocale,
} from './translations/index.js';
const messages = Object.assign(languages);
const dateTimeFormatsES = Object.assign(dateTimeFormats);
const numberFormatsES = Object.assign(numberFormats);
let i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'es',
  messages: messages,
  dateTimeFormats: dateTimeFormatsES,
  numberFormats: numberFormatsES,
});

i18n.n1 = i18n.n; // save default i18n.n to i18n.n1

i18n.n = function (nValue, nStyle = 'decimal') {
  if (nValue === '') {
    return '';
  }
  let numberFormatter = this.getNumberFormat(this.locale); // get NumberFormat Based on locale
  if (
    numberFormatter[nStyle].minimizarMiles != undefined &&
    numberFormatter[nStyle].minimizarMiles
  ) {
    if (
      nValue >= 1000 ||
      (nValue >= 10 && numberFormatter[nStyle].style == 'percent')
    ) {
      let newValue = nValue / 1000;
      let valueFormat = this.n1(newValue, nStyle).replace(',00', '');
      if (Math.abs(newValue) >= 1000 && Math.abs(newValue) < 10000) {
        let addEnPos = newValue < 0 ? 2 : 1;
        valueFormat = [
          valueFormat.slice(0, addEnPos),
          '.',
          valueFormat.slice(addEnPos),
        ].join('');
      }

      return numberFormatter[nStyle].style == 'currency'
        ? valueFormat.replace('€', '').trim() + 'k €'
        : numberFormatter[nStyle].style == 'percent'
          ? valueFormat.replace('%', '').trim() + 'k %'
          : valueFormat +
            'k' +
            (numberFormatter[nStyle].esUnidades
              ? ' uds.'
              : numberFormatter[nStyle].esDias
                ? ' días'
                : '');
    }
  }

  if (numberFormatter[nStyle].redondear) {
    let newValue = Math.abs(nValue) > 1000 ? Math.round(nValue) : nValue;
    let valueFormat = this.n1(newValue, nStyle).replace(',00', '');
    if (Math.abs(newValue) >= 1000 && Math.abs(newValue) < 10000) {
      let addEnPos = newValue < 0 ? 2 : 1;
      valueFormat = [
        valueFormat.slice(0, addEnPos),
        '.',
        valueFormat.slice(addEnPos),
      ].join('');
    }
    return (
      valueFormat +
      (numberFormatter[nStyle].esUnidades
        ? ' uds.'
        : numberFormatter[nStyle].esDias
          ? ' días'
          : '')
    );
  }

  return (
    this.n1(nValue, nStyle) +
    (numberFormatter[nStyle].esUnidades
      ? ' uds.'
      : numberFormatter[nStyle].esDias
        ? ' días'
        : '')
  );
};

//Importamos la librería de los gráficos
var VueD3 = require('vue-d3');
Vue.use(VueD3);

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  render: (h) => h(App),
}).$mount('#app');
