<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3 v-if="!esNuevo">{{ titleEditar }}</h3>
        <h3 v-else>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <card-form :style="combinedStyles" title="Informacion básica">
          <template>
            <card-form-item title="Nombre">
              <template #description>
                <input-box v-model="itemMod.nombre" v-bind="form.nombre" />
              </template>
            </card-form-item>
            <card-form-item title="Proveedor sellout">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_proveedor_sellout"
                    label="Proveedor-sellout"
                    :filter="true"
                    :options="opcionesProveedorSellout"
                    v-bind="form.nombre_proveedor_sellout"
                  />
                </div>
              </template>
            </card-form-item>
            <card-form-item title="URL fedicom">
              <template #description>
                <input-box
                  v-model="itemMod.url_fedicom"
                  v-bind="form.url_fedicom"
                />
              </template>
            </card-form-item>
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import proveedores from '@/api/proveedores.api';
import colors from '@/mixins/colors.js';
import CheckSelector from '../../commons/forms/CheckSelector.vue';
import MultipleSelectCustom from '../../commons/forms/MultipleSelectCustom.vue';

export default {
  name: 'ModalProveedores',
  components: { MultipleSelectCustom, CheckSelector },
  extends: proveedores,
  mixins: [colors],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    esNuevo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      titleEditar: 'Editar',
      titleCrear: 'Crear',
      itemMod: {},
      opcionesProveedorSellout: [],
      form: {
        nombre: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre_proveedor_sellout: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        url_fedicom: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      esEstandar: true,
    };
  },
  watch: {
    item: {
      handler: function () {
        if (Object.keys(this.item).length > 0) {
          this.cargarDatosIniciales();
        }
        this.loadFiltros();
      },
      immediate: true,
    },
  },
  methods: {
    cargarDatosIniciales(data) {
      this.itemMod = { ...this.item };
    },
    actualizarCheck: function (data) {
      console.log(data);
    },

    guardar() {
      this.cleanForm();
      if (!this.validateForm()) {
        return;
      }
      this.itemMod = {
        ...this.itemMod,
      };
      if (this.esNuevo) {
        let createPromise = new Promise((resolve) => {
          this.createProveedor(resolve, this.itemMod);
        });
        createPromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      } else {
        let updatePromise = new Promise((resolve) => {
          this.updateProveedor(
            resolve,
            this.itemMod,
            this.itemMod.id_proveedor
          );
        });
        updatePromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      }
    },
    loadFiltros: function () {
      this.loading = true;
      let proveedoresSelloutPromise = new Promise((resolve) => {
        this.getProveedoresSellout(resolve);
      });
      Promise.all([proveedoresSelloutPromise]).then(
        ([proveedoresSelloutData]) => {
          console.log('proveedores sellout', proveedoresSelloutData);
          this.opcionesProveedorSellout = proveedoresSelloutData.map((item) => {
            item.text = item.nombre;
            item.value = item.id_proveedor_sellout;
            return item;
          });
          this.loading = false;
        }
      );
    },

    validateForm() {
      let validado = true;
      if (!this.itemMod.descripcion === '') {
        this.form.descripcion.hasError = true;
        this.form.descripcion.hasValidation = true;
        this.form.descripcion.errors.push({
          show: true,
          message: this.$t('error.obligatorio.default'),
        });
        validado = false;
      }
      return validado;
    },
    cleanForm: function () {
      this.form = {
        descripcion: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scope>
.form {
  overflow: visible;
}
</style>
